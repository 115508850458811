import React, { useEffect, useState } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ShowShareModal from 'layouts/projects/ShowShareModal';
import { ToastContainer, toast } from 'react-toastify';

const View = () => {
    const { slug } = useParams();
    const [companyDetails, setCompanyDetails] = useState([]);
    const [projects, setProjects] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [activeTab, setActiveTab] = useState('projects');

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const navigate = useNavigate();
    const [isReadMore, setIsReadMore] = useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const fetchUsertDetails = async () => {
        console.log(slug)
        try {
            const response = await fetch(`${apiUrl}api/organization/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                setCompanyDetails(result);
                setProjects(result?.projects)
                setJobs(result?.jobs)
                console.log(result)

            } else {
                setError('Failed to fetch experts details.');
            }
        } catch (error) {
            console.error('Error fetching role details:', error);
            setError('Failed to fetch experts details. Please try again later.');
        }
    };

    const parseOperationalCountries = (countries) => {
        if (Array.isArray(countries)) {
            return countries;
        }
        try {
            return JSON.parse(countries);
        } catch (e) {
            return [];
        }
    };

    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : 'N/A';
        return formattedDeadline;
    }

    const copyURLToClipboard = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL);
        setShowCopyModal(false)
        toast.success('Link copied to clipboard!');
    };

    useEffect(() => {
        fetchUsertDetails();
    }, []);

    return (
        <BasicLayout>
            <ToastContainer />
            <ShowShareModal
                show={showCopyModal}
                handleClose={() => setShowCopyModal(false)}
                copyURLToClipboard={copyURLToClipboard}
            />
            <div className="container project-details-container w-100 mt-5 py-5">
                {/* <div className={showModal || showCopyModal ? 'blurred-background' : ''}> */}
                <div className='container detail-row'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='company-info shadow'>
                                <div className="company-logo" style={{ backgroundColor: companyDetails?.logo ? '#fff' : '#fff', color: companyDetails?.logo ? '#fff' : '#000' }}>

                                    {companyDetails?.logo && (
                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${companyDetails?.logo}`} alt="Profile picture" />
                                    )}
                                </div>
                                <button className='btn btn-share' onClick={() => setShowCopyModal(true)}><span class="material-symbols-outlined">share</span></button>
                                <h2>{companyDetails?.company_name}</h2>
                                <hr></hr>
                                <div className='project-info'>
                                    {/* <button className='btn btn-share' onClick={() => navigate(-1)}><span class="material-symbols-outlined">share</span></button> */}
                                    <h2 className='mb-2'>Company Details</h2>
                                    {companyDetails?.website_link && (
                                        <p><strong>Website: </strong> <a href={companyDetails?.website_link} target="_blank">
                                            <span className="text-primary">{companyDetails?.website_link}</span>
                                        </a>
                                        </p>
                                    )}
                                    {companyDetails?.incorporation_date && (
                                        <p>Incorporation Date: <span>{convertDate(companyDetails?.incorporation_date)}</span></p>
                                    )}
                                    {companyDetails?.organization_type && (
                                        <p><strong>Organization Type: </strong><span className="text-muted">{companyDetails?.organization_type}</span></p>
                                    )}
                                    {companyDetails?.headquarter && (
                                        <p><strong>Headquarter : </strong><span className="text-muted">{companyDetails?.headquarter}</span></p>
                                    )}
                                    {companyDetails?.address && (
                                        <p><strong>Office Address : </strong><span className="text-muted">{companyDetails?.address}</span></p>
                                    )}
                                    {companyDetails?.operational_countries && (
                                        <p><strong>Operational Countries: </strong><span className="text-muted">{parseOperationalCountries(companyDetails?.operational_countries).join(', ')}</span></p>
                                    )}

                                    {companyDetails?.turnover && (
                                        <p><strong>Turnover (in Million): </strong><span className="text-muted">{companyDetails?.turnover} {companyDetails?.currency || 'INR'}</span></p>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className="project-details">
                                {/* project details area */}
                                <div className="card-body">
                                    {companyDetails?.about && (
                                        <>
                                            <div className='card mb-3 bg-white rounded'>
                                                <div className='card-header'><h2>About Organization</h2></div>
                                                <div className="card-body">
                                                    <div className='fs-6'>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: isReadMore
                                                                ? companyDetails?.about
                                                                : companyDetails?.about?.length > 300
                                                                    ? `${companyDetails?.about.slice(0, 300)}...`
                                                                    : companyDetails?.about,
                                                        }}></div>
                                                        {companyDetails?.about?.length > 300 && (
                                                            <button className="btn btn-link p-0" onClick={toggleReadMore}>
                                                                {isReadMore ? 'Read Less' : 'Read More'}
                                                            </button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <ul className="nav nav-pills" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${activeTab === 'projects' ? 'active' : ''}`} id="projects-tab" onClick={() => setActiveTab('projects')} role="tab" aria-controls="projects" aria-selected={activeTab === 'projects'}>Projects</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${activeTab === 'jobs' ? 'active' : ''}`} id="jobs-tab" onClick={() => setActiveTab('jobs')} role="tab" aria-controls="jobs" aria-selected={activeTab === 'jobs'}>Jobs</button>
                                        </li>
                                    </ul>

                                    <div className="tab-content mt-md-3" id="myTabContent">
                                        <div className='tab-pane project-meta fade show active' id="projects" role="tabpanel" aria-labelledby="projects-tab">
                                            {activeTab === 'projects' ?
                                                <>
                                                    <h3>Projects</h3>
                                                    <hr></hr>
                                                    <div className='col-md-12 mt-2'>
                                                        {projects?.length > 0
                                                            ?
                                                            <>
                                                                {projects?.map((project, index) => (
                                                                    <div className="col-md-12 py-3" key={index}>
                                                                        <div className="card project-list shadow">
                                                                            <div className="card-body">
                                                                                <div className="row align-items-center company-title">
                                                                                    <div className="col-md-9">
                                                                                        <Link to={`/project/${project?.slug}`} >
                                                                                            <h2> {project?.name} </h2>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className='col-md-3 post-meta'>
                                                                                        <p>
                                                                                            <i className="material-icons fs-6" title="Posted on">event</i>
                                                                                            <span className="fs-6 text-muted"> Posted on  {convertDate(project?.created_at)}</span>
                                                                                        </p>
                                                                                        {convertDate(project?.deadline) && (
                                                                                            <p>
                                                                                                <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                                                <span className="fs-6 text-muted"> Closing on  {convertDate(project?.deadline)}</span>
                                                                                            </p>
                                                                                        )}
                                                                                        <p className='px-3'>
                                                                                            <i className="material-icons fs-6" title={project?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                                            >info</i>
                                                                                            <span className="fs-6 text-muted"> {project?.stage}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <hr></hr>
                                                                                <div className='row meta-info mb-3'>
                                                                                    <div className="col-md-4 pt-2 ">
                                                                                        <label>Sector</label>
                                                                                        <h6>{project?.sector}</h6>
                                                                                    </div>
                                                                                    <div className="col-md-4 pt-2 ">
                                                                                        <label>Sub Sector</label>
                                                                                        <h6>{project?.sub_sector}</h6>
                                                                                    </div>
                                                                                    <div className="col-md-4 pt-2 float-end">
                                                                                        <label>Location</label>
                                                                                        <h6>{project?.location}</h6>
                                                                                    </div>
                                                                                    <div className="col-md-12 pt-2 float-end">
                                                                                        <label>Required Positions</label>
                                                                                        <h6>
                                                                                            {project?.jobs?.map(job => job?.title).join(', ')}
                                                                                        </h6>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                ))}


                                                            </>
                                                            :
                                                            <>
                                                                <div className="col-md-12 mt-5">
                                                                    <div className="card-shadow">
                                                                        <h2 className='text-center'>No Records Found !!</h2>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <h3 className='mt-3 text-center'>Jobs</h3>
                                                    <div className='col-md-12 mt-2'>

                                                        {jobs?.length > 0
                                                            ?
                                                            <>
                                                                <div className='job-list project-list'>
                                                                    {jobs?.map((project, index) => (
                                                                        <div className="col-md-12 py-3" key={index}>
                                                                            <div className="card project-list shadow">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center company-title">
                                                                                        <div className="col-md-9">
                                                                                            <Link to={`/job/${project?.slug}`} >
                                                                                                <h2 className="text-truncate">
                                                                                                    {project?.title}
                                                                                                </h2>
                                                                                            </Link>

                                                                                        </div>
                                                                                        <div className='col-md-3 post-meta'>
                                                                                            <p>
                                                                                                <i className="material-icons fs-6" title="Posted on">event</i>
                                                                                                <span className="fs-6 text-muted"> Posted on  {convertDate(project?.created_at)}</span>
                                                                                            </p>
                                                                                            {convertDate(project?.deadline) && (
                                                                                                <p>
                                                                                                    <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                                                    <span className="fs-6 text-muted"> Closing on  {convertDate(project?.deadline)}</span>
                                                                                                </p>
                                                                                            )}
                                                                                            <p>
                                                                                                <i className="material-icons fs-6" title={project?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                                                >info</i>
                                                                                                <span className="fs-6 text-muted"> {project?.stage}</span>
                                                                                            </p>

                                                                                        </div>
                                                                                    </div>

                                                                                    <hr></hr>
                                                                                    <div className='row mb-3 meta-info'>
                                                                                        <div className="col-md-4 pt-2 ">
                                                                                            <label>Sector</label>
                                                                                            <h6>{project?.sector}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-4 pt-2 ">
                                                                                            <label>Sub Sector</label>
                                                                                            <h6>{project?.sub_sector}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-4 pt-2 float-end">
                                                                                            <label>Location</label>
                                                                                            <h6>{project?.location}</h6>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                <div className="col-md-12 mt-5">
                                                                    <div className="card-shadow">
                                                                        <h2 className='text-center'>No Records Found !!</h2>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </BasicLayout>
    );
}

export default View;
