import React, { useEffect, useState } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ShowContactModal from './ShowContactModal';

const View = () => {
    const { slug } = useParams();
    const [userDetails, setUserDetails] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const navigate = useNavigate();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const fetchUsertDetails = async () => {
        console.log(slug)
        try {
            const response = await fetch(`${apiUrl}api/experts/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                setUserDetails(result);
                console.log(result)

            } else {
                setError('Failed to fetch experts details.');
            }
        } catch (error) {
            console.error('Error fetching role details:', error);
            setError('Failed to fetch experts details. Please try again later.');
        }
    };

    const downloadPDF = () => {
        // Retrieve the URL of the PDF file from formData.resume_attachment
        const pdfUrl = `${apiUrl}${node === 'production' ? 'public/' : ''}storage/` + userDetails?.details?.resume_attachment;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'resume.pdf'; // Set the filename for download

        // Append the anchor element to the body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };
    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    function convertDateFormat(dateString) {
        const dateParts = dateString.split('-');
        const year = dateParts[0];
        const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
        return `${month} ${year}`;
    }

    const handleContact = (email) => {
        if (email) {
            setShowModal(true)

        }
    }

    useEffect(() => {
        fetchUsertDetails();
    }, []);

    return (
        <BasicLayout>
            <ShowContactModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                email={userDetails?.email}
                name={userDetails?.name}

            />
            {/* <div className='inner-banner'>
                <button className='btn btn-dark float-end' onClick={() => navigate(-1)}>Back</button>
            </div>

            <div className="container professional-details-wrapper mt-1">
                {token && user?.user?.role_id == 3 && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-end p-3">

                                        <button className="btn btn-primary d-flex mx-3" onClick={() => handleContact(userDetails?.email)}
                                        >
                                            Contact <i className="material-icons px-1">mail</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='card shadow p-5'>
                    <div className='row prof-info'>
                        <div className="col-lg-3 mb-4 professional-profile mb-lg-0">
                            {userDetails?.details?.profile_picture ?
                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${userDetails?.details?.profile_picture}`} alt="Profile picture" className="img-fluid" />

                                :

                                <div
                                    className="profile-circle position-absolute top-50 start-50 translate-middle"
                                    style={{
                                        width: '100px',  
                                        height: '100px', 
                                        borderRadius: '50%',  
                                        backgroundColor: '#f0f0f0',  
                                        display: 'flex',  
                                        alignItems: 'center',  
                                        justifyContent: 'center',  
                                    }}
                                >
                                    <span
                                        className="text-uppercase"
                                        style={{
                                            fontSize: '30px',
                                            lineHeight: '1',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {userDetails?.name?.charAt(0)}
                                    </span>
                                </div>

                            }
                        </div>
                        <div className="col-lg-9 px-xl-4">
                            <div className="meta-info-1">
                                <h2>{userDetails?.name}</h2>
                                <hr></hr>
                                <h4 className="">{userDetails?.details?.designation}</h4>
                                <ul className="list-unstyled">
                                    {userDetails?.details?.infracon_id && (
                                        <li><span>Infracon Id:</span> {userDetails?.details?.infracon_id}</li>
                                    )}
                                    {userDetails?.details?.availability && (
                                        <li><span><strong>Availability:</strong></span> {userDetails?.details?.availability}</li>
                                    )}
                                    {userDetails?.details?.years_of_experience && (
                                        <li><span>Experience:</span> {userDetails?.details?.years_of_experience} Years</li>
                                    )}

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row meta-data align-items-center">
                    {userDetails?.details?.about ?
                        <div className="col-lg-12 mt-5 px-4">
                            <div>
                                <h2 className="">About {userDetails?.name && userDetails.name.split(' ')[0]}</h2>
                                <hr></hr>
                                <p>{userDetails?.details?.about}</p>
                            </div>
                        </div>
                        :
                        <div className='mt-5'></div>
                    }

                    <div className="col-lg-12 mt-2 px-4">
                        <div className="row">
                            <div className="col-lg-12 mb-4 mb-sm-5">
                                <div className="mb-4">
                                    <h2 className="">Sectors</h2>
                                    <hr></hr>

                                    {userDetails?.details?.sectors && userDetails.details.sectors.map((sector, index) => (
                                        <div className="badge bg-secondary mx-2 my-2 mb-3 p-3" key={index}>{sector}</div>
                                    ))}

                                </div>
                                {userDetails?.details?.skills && (
                                    <div className="mb-4">
                                        <h2 className="">Skills</h2>
                                        <hr></hr>
                                        {userDetails?.details?.skills && userDetails.details.skills.map((skill, index) => (
                                            <div className="badge bg-secondary mx-2 my-2 mb-3 p-3" key={index}>{skill}</div>
                                        ))}

                                    </div>
                                )}

                                {userDetails?.details?.experience && (
                                    <div className='mt-3'>
                                        <h2>Experience</h2>
                                        <hr></hr>
                                        <div className="accordion" id="accordionExample">
                                            {userDetails?.details?.experience.map((exp, index) => (
                                                <div className="accordion-item mt-2" key={index}>
                                                    <h2 className="accordion-header" id={`heading${index}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                            {exp.title} <span className='px-1'> - {exp?.company_name}</span> <span className='px-1'> ({convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)})</span>
                                                        </button>

                                                    </h2>
                                                    <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                        <div className="accordion-body d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <h5 className='text-bold'>{exp.title}</h5>
                                                                <small className='text-muted fs-8'>{convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)}  {exp?.employment_type && (` (${exp?.employment_type})`)}</small>
                                                                <h6 className="pt-3"><strong>Company Name: </strong><span>{exp?.company_name}</span></h6>
                                                                <h6 className="pt-1"><strong>Location: </strong><span>{exp?.location}</span></h6>
                                                                {exp?.description && (
                                                                    <p className='text-muted pt-3'>
                                                                        <small>{showFullDescription ?
                                                                            <span dangerouslySetInnerHTML={{ __html: exp?.description }} /> :
                                                                            <span dangerouslySetInnerHTML={{ __html: exp?.description.substring(0, 200) + "..." }} />
                                                                        }
                                                                            {exp?.description.length > 200 && (
                                                                                <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                                    {showFullDescription ? " View less" : " View more"}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                    </p>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                )}
                                {userDetails?.details?.education && userDetails?.details?.education.length > 0 && (
                                    <div className='mt-4'>
                                        <h2>Education</h2>
                                        <hr></hr>
                                        <div className="accordion" id="accordionEducation">
                                            {userDetails?.details?.education.map((edu, index) => (
                                                <div className="accordion-item" key={index}>
                                                    <h2 className="accordion-header" id={`edu-heading${index}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#edu-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                            {edu.school} <span className='px-1'> ({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})</span>
                                                        </button>
                                                    </h2>
                                                    <div id={`edu-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`edu-heading${index}`} data-bs-parent="#accordionEducation">
                                                        <div className="accordion-body d-flex justify-content-between align-items-center">
                                                            <div className='meta-info'>
                                                                <h5>{edu.school}</h5>
                                                                <h6 className='text-muted fs-8'>({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})  {edu?.employment_type && (` (${edu?.employment_type})`)}</h6>
                                                                {edu?.degree && (
                                                                    <p className="pt-3"><strong>Degree: </strong><span>{edu?.degree}</span></p>
                                                                )}
                                                                {edu?.study && (
                                                                    <p className="pt-1"><strong>Field of study: </strong><span>{edu?.study}</span></p>
                                                                )}
                                                                {edu?.grade && (
                                                                    <p className="pt-1"><strong>Grade: </strong><span>{edu?.grade}</span></p>
                                                                )}
                                                                {edu?.description && (
                                                                    <p className='text-muted pt-3'>
                                                                        {showFullDescription ?
                                                                            <span dangerouslySetInnerHTML={{ __html: edu?.description }} /> :
                                                                            <span dangerouslySetInnerHTML={{ __html: edu?.description.substring(0, 200) + "..." }} />
                                                                        }
                                                                        {edu?.description.length > 200 && (
                                                                            <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                                {showFullDescription ? " View less" : " View more"}
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                )}
                                {userDetails?.details?.certification && userDetails?.details?.certification.length > 0 && (
                                    <div className='mt-4'>
                                        <h2>Licenses & certifications</h2>
                                        <hr></hr>
                                        <div className="accordion" id="accordionCertification">
                                            {userDetails?.details?.certification.map((cert, index) => (
                                                <div className="accordion-item" key={index}>
                                                    <h2 className="accordion-header" id={`cert-heading${index}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#cert-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                            {cert.name} - {cert.organization}
                                                        </button>

                                                    </h2>
                                                    <div id={`cert-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`cert-heading${index}`} data-bs-parent="#accordionCertification">
                                                        <div className="accordion-body d-flex justify-content-between align-items-center">
                                                            <div className='meta-info'>
                                                                {cert?.credential_url && (
                                                                    <Link to={cert?.credential_url} target="_blank">
                                                                        <h5>{cert.name} <span class="material-symbols-outlined"> link </span></h5>
                                                                    </Link>
                                                                )}

                                                                <h6>{cert?.organization}</h6>
                                                                {cert?.start_date && (
                                                                    <p><strong>Issued :</strong> {convertDateFormat(cert?.start_date)}</p>
                                                                )}
                                                                {cert?.end_date && (
                                                                    <p><strong>Expired :</strong> {convertDateFormat(cert?.end_date)}</p>
                                                                )}

                                                                {cert?.credential_id && (
                                                                    <p><strong>Credential ID:</strong> <span>{cert?.credential_id}</span></p>
                                                                )}

                                                                {cert?.credential_url && (
                                                                    <a class="btn-more" href={cert?.credential_url} role="button" target="_blank">Show Credential </a>
                                                                )}
                                                                {cert?.description && (
                                                                    <p className='text-muted pt-3'>
                                                                        <small>
                                                                            <span dangerouslySetInnerHTML={{ __html: cert?.description }} />
                                                                        </small>
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className='mt-4'>
                                    <h2 >Other Info  </h2>
                                    <hr></hr>
                                    <ul className="list-unstyled mb-4">
                                        {userDetails?.details?.current_location && (
                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Current Location:</strong></span> {userDetails?.details?.current_location}</li>
                                        )}
                                        {userDetails?.details?.preferred_location?.length > 0 && (
                                            <li className="mb-3 display-7">
                                                <span className="text-secondary me-2 font-weight-bold"><strong>Preferred Location:</strong></span>
                                                {userDetails.details.preferred_location.join(' || ')}
                                            </li>
                                        )}


                                        {userDetails?.details?.current_employer && (
                                            <li className="mb-3 display-7">
                                                <span className="text-secondary me-2 font-weight-bold"><strong>Current Employer:</strong></span>
                                                {userDetails.details.current_employer}
                                            </li>
                                        )}

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* New format */}
            <div className='inner-banner'>
                <div className='container d-flex justify-content-between align-items-center'>
                    <h2>Profile</h2>
                    <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>

            <div className="container profile-page mt-5">
                <div className='row'>
                    <div className='col-md-3 mb-md-5'>
                        <div className='card user-profile-left shadow'>
                            <div className="profile-image-holder" style={{ backgroundColor: userDetails?.details?.profile_picture ? '#fff' : '#fff', color: userDetails?.details?.profile_picture ? '#fff' : '#000' }}>
                                {userDetails?.details?.profile_picture ? (
                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${userDetails?.details?.profile_picture}`} alt="Profile picture" />
                                ) : (
                                    <>
                                        <div
                                            className="profile-circle position-absolute top-50 start-50 translate-middle"
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '50%',
                                                backgroundColor: '#f0f0f0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '3px solid #07ad7c',
                                                borderRadius: '100%'
                                            }}
                                        >

                                            <span
                                                className="text-uppercase"
                                                style={{
                                                    fontSize: '30px',
                                                    lineHeight: '1',
                                                    fontWeight: 'bold'
                                                }}
                                            ><strong>{userDetails?.name?.charAt(0)}</strong></span>
                                        </div>
                                    </>

                                )}
                            </div>
                            {token && user?.user?.role_id == 3 && user?.user?.is_approved == 1 && (
                                <>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary d-flex" onClick={() => handleContact(userDetails?.email)}
                                        >
                                            Contact <i className="material-icons px-1">mail</i>
                                        </button>

                                    </div>
                                </>
                            )}
                            <hr></hr>
                            <div className='profile-meta'>

                                <h4>{userDetails?.name}</h4>
                                {userDetails?.details?.designation && (
                                    <h6><span class="material-symbols-outlined"> business_center </span>&nbsp; {userDetails?.details?.designation}</h6>
                                )}
                                {userDetails?.details?.years_of_experience && (
                                    <>
                                        <label>Experience </label>
                                        <p><span class="material-symbols-outlined"> avg_pace </span>&nbsp; {userDetails?.details?.years_of_experience} year</p>
                                    </>
                                )}
                                {userDetails?.details?.sectors && (
                                    <>
                                        <label>Sector</label>
                                        <p><span class="material-symbols-outlined"> workspaces </span>&nbsp; {userDetails?.details?.sectors.join(" || ")}</p>
                                    </>

                                )}
                                {userDetails?.details?.skills && (
                                    <>
                                        <label>Skills</label>
                                        <p><span class="material-symbols-outlined"> hub </span>&nbsp; {userDetails?.details?.skills.join(" || ")}</p>
                                    </>

                                )}
                                {userDetails?.details?.current_location && (
                                    <>
                                        <label>Current Location</label>
                                        <p><span class="material-symbols-outlined"> location_on </span>&nbsp; {userDetails?.details?.current_location}</p>
                                    </>
                                )}
                                {userDetails?.details?.preferred_location && (
                                    <>
                                        <label>Preferred Location</label>
                                        <p><span class="material-symbols-outlined"> location_on </span>&nbsp; {userDetails?.details?.preferred_location.join(' || ')}</p>
                                    </>
                                )}

                                {userDetails?.details?.infracon_id && (
                                    <>
                                        <label>Infracon Id</label>
                                        <p><span class="material-symbols-outlined"> apps </span>&nbsp; {userDetails?.details?.infracon_id}</p>
                                    </>
                                )}
                                {userDetails?.details?.availability && (
                                    <>
                                        <label>Availability </label>
                                        <p><span class="material-symbols-outlined"> event_available </span>&nbsp; {userDetails?.details?.availability}</p>
                                    </>
                                )}

                            </div>

                        </div>
                    </div>
                    <div className='col-md-9 user-information'>

                        <div className="row">
                            <div className="col-md-12">
                                {userDetails?.details?.about && (
                                    <>
                                        <div className="card mb-4">
                                            <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="me-auto">About {userDetails?.name?.split(' ')[0] || ''}</h5>

                                            </div>

                                            <div className="card-body">
                                                <p>{userDetails?.details?.about}</p>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* Experience */}
                                {userDetails?.details?.experience && userDetails?.details?.experience.length > 0 && (
                                    <div className='card mb-4'>
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="me-auto">Experience</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="accordion" id="accordionExample">
                                                {userDetails?.details?.experience.map((exp, index) => (
                                                    <div className="accordion-item mt-2" key={index}>
                                                        <h2 className="accordion-header" id={`heading${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                                {exp.title} <span className='px-1'> - {exp?.company_name}</span> <span className='px-1'> ({convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)})</span>
                                                            </button>

                                                        </h2>
                                                        <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <h5 className='text-bold'>{exp.title}</h5>
                                                                    <small className='text-muted fs-8'>{convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)}  {exp?.employment_type && (` (${exp?.employment_type})`)}</small>
                                                                    <h6 className="pt-3"><strong>Company Name: </strong><span>{exp?.company_name}</span></h6>
                                                                    <h6 className="pt-1"><strong>Location: </strong><span>{exp?.location}</span></h6>
                                                                    {exp?.description && (
                                                                        <p className='text-muted pt-3'>
                                                                            <small>{showFullDescription ?
                                                                                <span dangerouslySetInnerHTML={{ __html: exp?.description }} /> :
                                                                                <span dangerouslySetInnerHTML={{ __html: exp?.description.substring(0, 200) + "..." }} />
                                                                            }
                                                                                {exp?.description.length > 200 && (
                                                                                    <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                                        {showFullDescription ? " View less" : " View more"}
                                                                                    </span>
                                                                                )}
                                                                            </small>
                                                                        </p>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                )}

                                {/* Education */}
                                {userDetails?.details?.education && userDetails?.details?.education.length > 0 && (
                                    <div className='card mb-4'>
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="me-auto">Education</h5>
                                        </div>
                                        <div className='card-body'>
                                            <div className="accordion" id="accordionEducation">
                                                {userDetails?.details?.education.map((edu, index) => (
                                                    <div className="accordion-item" key={index}>
                                                        <h2 className="accordion-header" id={`edu-heading${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#edu-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                                {edu.school} <span className='px-1'> ({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})</span>
                                                            </button>
                                                        </h2>
                                                        <div id={`edu-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`edu-heading${index}`} data-bs-parent="#accordionEducation">
                                                            <div className="accordion-body d-flex justify-content-between align-items-center">
                                                                <div className='meta-info'>
                                                                    <h5>{edu.school}</h5>
                                                                    <h6 className='text-muted fs-8'>({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})  {edu?.employment_type && (` (${edu?.employment_type})`)}</h6>
                                                                    {edu?.degree && (
                                                                        <p className="pt-3"><strong>Degree: </strong><span>{edu?.degree}</span></p>
                                                                    )}
                                                                    {edu?.study && (
                                                                        <p className="pt-1"><strong>Field of study: </strong><span>{edu?.study}</span></p>
                                                                    )}
                                                                    {edu?.grade && (
                                                                        <p className="pt-1"><strong>Grade: </strong><span>{edu?.grade}</span></p>
                                                                    )}
                                                                    {edu?.description && (
                                                                        <p className='text-muted pt-3'>
                                                                            {showFullDescription ?
                                                                                <span dangerouslySetInnerHTML={{ __html: edu?.description }} /> :
                                                                                <span dangerouslySetInnerHTML={{ __html: edu?.description.substring(0, 200) + "..." }} />
                                                                            }
                                                                            {edu?.description.length > 200 && (
                                                                                <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                                    {showFullDescription ? " View less" : " View more"}
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* Licenses */}
                                {userDetails?.details?.certification && userDetails?.details?.certification.length > 0 && (
                                    <div className='card mb-4'>
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="me-auto">Licenses & certifications</h5>
                                        </div>
                                        <div className='card-body'>
                                            <div className="accordion" id="accordionCertification">
                                                {userDetails?.details?.certification.map((cert, index) => (
                                                    <div className="accordion-item" key={index}>
                                                        <h2 className="accordion-header" id={`cert-heading${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#cert-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                                {cert.name} - {cert.organization}
                                                            </button>

                                                        </h2>
                                                        <div id={`cert-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`cert-heading${index}`} data-bs-parent="#accordionCertification">
                                                            <div className="accordion-body d-flex justify-content-between align-items-center">
                                                                <div className='meta-info'>
                                                                    {cert?.credential_url && (
                                                                        <Link to={cert?.credential_url} target="_blank">
                                                                            <h5>{cert.name} <span class="material-symbols-outlined"> link </span></h5>
                                                                        </Link>
                                                                    )}

                                                                    <h6>{cert?.organization}</h6>
                                                                    {cert?.start_date && (
                                                                        <p><strong>Issued :</strong> {convertDateFormat(cert?.start_date)}</p>
                                                                    )}
                                                                    {cert?.end_date && (
                                                                        <p><strong>Expired :</strong> {convertDateFormat(cert?.end_date)}</p>
                                                                    )}

                                                                    {cert?.credential_id && (
                                                                        <p><strong>Credential ID:</strong> <span>{cert?.credential_id}</span></p>
                                                                    )}

                                                                    {cert?.credential_url && (
                                                                        <a class="btn-more" href={cert?.credential_url} role="button" target="_blank">Show Credential </a>
                                                                    )}
                                                                    {cert?.description && (
                                                                        <p className='text-muted pt-3'>
                                                                            <small>
                                                                                <span dangerouslySetInnerHTML={{ __html: cert?.description }} />
                                                                            </small>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </BasicLayout>
    );
}

export default View;
