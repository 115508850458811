import React from 'react'
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Link, useNavigate } from "react-router-dom";
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { useState, useEffect } from "react";
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import CustomLoader from 'components/CustomLoader'

const RecoverAccount = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(180); // 3 minutes in seconds
  const [showTimer, setShowTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [error, seterror] = useState(null);
  const [verify, setVerify] = useState(false);

  const handleOtp = (e) => {
    setOtp(e.target.value);
  };

  const sendOtp = async () => {
    setLoading(true);
    seterror('');
    try {
      const response = await axios.post(`${apiUrl}api/send-otp`, { email: email, recovery: true });
      if (response.status === 200) {
        setLoading(false);
        setOtpSend(true);
        setShowTimer(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          console.log(error.response.data.errors.email)
          seterror(error.response.data.errors);
          setLoading(false);
        } else {
          seterror(error.response.data.error);
          setLoading(false);
        }
      } else if (error.request) {
        seterror('No response received from the server');
        setLoading(false);
      } else {
        seterror('An error occurred while making the request');
        setLoading(false);

      }
    }
  };

  useEffect(() => {
    let countdown;
    if (showTimer && timer > 0) {
      countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [showTimer, timer]);

  useEffect(() => {
    if (timer === 0) {
      setOtpSend(false);
      setShowTimer(false);
      setTimer(180); // Reset timer for next use
    }
  }, [timer]);

  const handleVerify = async () => {
    try {
      const response = await axios.post(`${apiUrl}api/verify-otp`, {
        email: email,
        otp: otp,
        recovery: true
      });
      if (response.status === 200) {
        setVerify(true);
        setShowTimer(false);
        // navigate('/sign-in')
        toast.success('Account recovered successfully!');

        // Redirect after 1 second
        setTimeout(() => {
          navigate('/sign-in');
        }, 1500); // 1000 milliseconds = 1 second
      }
    } catch (error) {
      if (error.response) {
        seterror(error.response.data.message);
      } else if (error.request) {
        seterror('No response received from the server');
      } else {
        seterror('An error occurred while making the request');
      }
    }
  };

  return (
    <BasicLayout>
      <ToastContainer
      />
      {loading && (
        <div className="spinner-overlay">
          <CustomLoader />
        </div>
      )}
      <div className="login-wrapper">
        <div className="container">
          <div className="row vh-100 d-flex align-items-center justify-content-center">
            <div className="col-md-8 login-box">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-md-7 p-5">
                  <h4>Wellcome Back to Proxtal</h4>
                  <p>The First ever Construction And Civil job Management Portal</p>
                </div>
                <div className="col-md-5 p-4">
                  <div className="login">
                    <h4>Account Recovery</h4>
                    <hr></hr>
                    <MDBox component="form" role="form">
                      <MDBox mb={2}>
                        <MDInput type="email" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                        {error && (
                          <MDTypography variant="caption" color="error">
                            {error.email}
                          </MDTypography>
                        )}
                      </MDBox>
                      {!showTimer && !verify ? (
                        <MDBox mt={4} mb={1}>
                          <MDButton variant="btn btn-warning" color="info" onClick={sendOtp} fullWidth >
                            Send OTP
                          </MDButton>
                        </MDBox>
                      ) : ''}
                      {showTimer && otpSend && (
                        <>

                          <MDBox display="flex" alignItems="center" mb={2}>
                            <MDInput
                              type="text"
                              label="OTP"
                              name="otp"
                              variant="outlined"
                              fullWidth
                              onChange={handleOtp}
                              helperText={error ? (
                                <MDTypography variant="caption" color="error">
                                  {error}
                                </MDTypography>
                              ) :
                                <MDTypography variant="caption" color="success">
                                  OTP has been sent to your email
                                </MDTypography>
                              }
                              required
                            />
                          </MDBox>
                          <div>
                            <p>Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</p>
                          </div>

                          <MDBox mt={2} mb={1}>
                            <MDButton variant="btn btn-warning" color="info" onClick={handleVerify} fullWidth >
                              Confirm OTP
                            </MDButton>
                          </MDBox>
                        </>

                      )}

                    </MDBox>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

    </BasicLayout>
  )
}

export default RecoverAccount