import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../store/slices/authSlice';
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { ToastContainer, toast } from 'react-toastify';
import { Autocomplete, TextField } from '@mui/material';
import countryData from '../../../../assets/data/country-state.json';
const currencyCodes = [
  "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];
const Create = () => {
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const { token, user } = useSelector(selectAuth);
  const [showAlert, setShowAlert] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedSubsector, setSelectedSubsector] = useState(null);
  const [subsectors, setSubsectors] = useState([]);
  const [showTender, setShowTender] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedCurrentLocation, setSelectedCurrentLocation] = useState('');
  const [stateCountryList, setStateCountryList] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');

  const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
  const navigate = useNavigate()

  // State variables to store form data
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    sector: '',
    sub_sector: '',
    stage: '',
    tender_reference_number: '',
    deadline: '',
    location: '',
    jd_image: null,
    features: '',
    budget: '',
    currency: '',
    key_personnel_details: [{
      title: '',
      category: '',
      sector: '',
      sub_sector: '',
      stage: '',
      experience: '',
      deadline: '',
      location: '',
      language: '',
      salary: '',
      currency: '',
      job_type: '',
      personnel_type: '',
      description: ''
    }]
    // key_personnel_details: [{ key_personnel_type: 'National', name: '', description: '' }]
  });

  const handleSelectCurrency = (code) => {
    setSelectedCurrency(code);
    setFormData({ ...formData, currency: code });
  };
  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, type } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      const { value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  // Function to handle input changes in personnel field
  const handlePersonnelChange = (index, field, value) => {
    const updatedPersonnel = [...formData.key_personnel_details];
    updatedPersonnel[index][field] = value;
    setFormData({
      ...formData,
      key_personnel_details: updatedPersonnel
    });
  };

  // // Function to add a new empty personnel field
  // const addPersonnelField = () => {
  //   const lastPersonnel = formData.key_personnel_details[formData.key_personnel_details.length - 1];
  //   if (Object.values(lastPersonnel).every(value => value.trim() !== '')) { // Check if the last personnel field is not empty
  //     setFormData({
  //       ...formData,
  //       key_personnel_details: [...formData.key_personnel_details, {
  //         title: '',
  //         category: '',
  //         sector: '',
  //         sub_sector: '',
  //         stage: '',
  //         experience: '',
  //         deadline: '',
  //         location: '',
  //         language: '',
  //         salary: '',
  //         job_type: '',
  //         personnel_type: '',
  //         description: ''
  //       }]
  //     });
  //   }
  // };

  // // Function to remove a personnel field
  // const removePersonnelField = (index) => {
  //   const updatedPersonnel = [...formData.key_personnel_details];
  //   updatedPersonnel.splice(index, 1);
  //   setFormData({
  //     ...formData,
  //     key_personnel_details: updatedPersonnel
  //   });
  // };

  const validatePersonnel = (personnel) => {
    const personnelErrors = {};
    if (!personnel.title) personnelErrors.title = 'Job Title is required';
    // if (!personnel.location) personnelErrors.location = 'Location is required';
    if (!personnel.language) personnelErrors.language = 'Languages are required';
    // if (!personnel.stage) personnelErrors.stage = 'Job Stage is required';
    // if (!personnel.sector) personnelErrors.sector = 'Sector is required';
    // if (!personnel.sub_sector) personnelErrors.sub_sector = 'Sub Sector is required';
    // if (!personnel.category) personnelErrors.category = 'Category is required';
    // if (!personnel.job_type) personnelErrors.job_type = 'Job Type is required';
    if (!personnel.experience) personnelErrors.experience = 'Experience is required';
    if (!personnel.deadline) personnelErrors.deadline = 'Deadline is required';
    if (!personnel.personnel_type) personnelErrors.personnel_type = 'Personnel Type is required';
    if (!personnel.description) personnelErrors.description = 'Description is required';

    return personnelErrors;
  };


  const addPersonnelField = () => {
    const lastPersonnel = formData.key_personnel_details[formData.key_personnel_details.length - 1];
    const personnelErrors = validatePersonnel(lastPersonnel);

    if (Object.keys(personnelErrors).length === 0) {
      setFormData({
        ...formData,
        key_personnel_details: [...formData.key_personnel_details, {
          title: '',
          category: '',
          sector: '',
          sub_sector: '',
          stage: '',
          experience: '',
          deadline: '',
          location: '',
          language: '',
          salary: '',
          currency: '',
          job_type: '',
          personnel_type: '',
          description: ''
        }]
      });
      setErrors({});
    } else {
      // setErrors({ key_personnel_details: personnelErrors });
      toast.error("Please fill all required fields", {
        className: "toast-message",
      });
    }
  };

  const removePersonnelField = (index) => {
    if (window.confirm('Are you sure you want to delete this? Once deleted, you cannot retrieve it.')) {

      const updatedPersonnel = [...formData.key_personnel_details];
      updatedPersonnel.splice(index, 1);
      setFormData({
        ...formData,
        key_personnel_details: updatedPersonnel
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Project Name is required';
    if (!formData.category) newErrors.category = 'Category is required';
    if (!formData.location) newErrors.location = 'Location is required';
    if (!formData.sector) newErrors.sector = 'Sector is required';
    if (!formData.sub_sector) newErrors.sub_sector = 'Sub Sector is required';
    if (!formData.stage) newErrors.stage = 'Project Stage is required';
    if (showTender && !formData.tender_reference_number) newErrors.tender_reference_number = 'Tender Reference Number is required';
    if (!formData.budget) newErrors.budget = 'Project Cost is required';
    if (!formData.deadline) newErrors.deadline = 'Deadline is required';
    if (!formData.features) newErrors.features = 'Features is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addProject = () => {
    if (validate()) {
      setProceedClicked(true)
    }

  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}api/projects`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate('/my-projects');
    } catch (error) {
      console.error('Error creating project:', error);
      // Handle error
      setError('Error creating project. Please try again.');
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}api/get-sectors`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setSectors(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Handle selection of a sector
  const handleSectorChange = (event) => {
    const sectorId = event.target.value;
    setSelectedSector(sectorId);
    setFormData({ ...formData, sector: sectorId });

    const sector = sectors.find(s => s.id === parseInt(sectorId));
    if (sector) {
      setSubsectors(sector.children);
    }
  };

  const handleSubsectorChange = (event) => {
    const subsectorId = event.target.value;
    setSelectedSubsector(subsectorId); // Set the selected subsector state
    setFormData({ ...formData, sub_sector: subsectorId }); // Update formData.sub_sector
  };

  const handleStageChange = (event) => {
    const stageValue = event.target.value;
    setShowTender(stageValue === 'Proposal');
    setFormData({ ...formData, stage: stageValue }); // Update formData.sub_sector
  };

  const getSectorName = (sectorId) => {
    const sector = sectors.find(sector => String(sector.id) === String(sectorId));
    return sector ? sector.name : '';
  };

  const fetchCountryStateList = () => {
    if (countryData && countryData.countries) {
      const list = countryData.countries.flatMap((country) =>
        country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
      );
      setStateCountryList(list);
    }
  }

  useEffect(() => {
    fetchCountryStateList();
    fetchData();
  }, [token]);

  return (
    <BasicLayout>
      <ToastContainer
      />
      {user && user?.user?.is_approved == 0 ?
        <>
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <div>
              <div className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="text-warning bi bi-emoji-frown-fill" width="75" height="75" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0Zm3.176 11.03a.5.5 0 0 1-.85.53A3.498 3.498 0 0 0 8 9.5a3.498 3.498 0 0 0-2.326 2.06.5.5 0 1 1-.85-.53A4.498 4.498 0 0 1 8 8.5a4.498 4.498 0 0 1 3.176 2.53ZM5.5 6a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm6 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>

              </div>
              <div className="text-center">
                <h1>Account Not Approved</h1>
                <p className='mb-2'>  Your account approval is still pending. You will be able to add projects once it has been approved. Please contact the admin for further assistance if necessary.</p>
                <MDButton variant="gradient" color="info" onClick={() => navigate('/support')}>
                  Contact Admin
                </MDButton>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className='inner-banner'>
            <div className='container'>
              {/* <button className='btn btn-dark' onClick={() => navigate(-1)}> Back </button> */}
              <h2>Add New Project</h2>
            </div>
          </div>
          <div className="container add-project-wrapper mt-2">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  {!proceedClicked ?
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-end mb-3">
                        </div>
                        <div className='row mt-3'>
                          <div className="form-group col-md-6">
                            <label htmlFor="name">Project Name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control mt-2" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                            {errors.name && <div className="text-danger fs-6 mt-2">{errors.name}</div>}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="category">Category <span className='text-danger'>*</span></label>
                            <select className="form-control mt-2" id="category" name="category" value={formData.category} onChange={handleInputChange} required>
                              <option value="">Select Category</option>
                              <option value='Goods'>Goods</option>
                              <option value='Works'>Works</option>
                              <option value='Consulting Services'>Consulting Services</option>
                              <option value='Other Non-Consulting Services'>Other Non-Consulting Services</option>
                            </select>
                            {errors.category && <div className="text-danger fs-6 mt-2">{errors.category}</div>}
                          </div>

                          <div className="form-group col-md-6 mt-4">
                            <label htmlFor="location">Location <span className='text-danger'>*</span></label>
                            {/* <Autocomplete
                          options={stateCountryList}
                          freeSolo
                          className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                          value={formData?.location}
                          required
                          name="location"
                          onChange={(event, newValue) => setSelectedCurrentLocation(newValue)}
                          renderInput={(params) => <TextField {...params} label="" />}
                        /> */}
                            <input type="text" className="form-control mt-2" id="location" name="location" value={formData?.location} onChange={handleInputChange} required />
                            {errors.location && <div className="text-danger fs-6 mt-2">{errors.location}</div>}
                          </div>
                          <div className="form-group col-md-6 mt-4">
                            <label htmlFor="stage">Project Stage <span className='text-danger'>*</span></label>
                            <select className="form-control mt-2" id="stage" name="stage" value={formData.stage} onChange={handleStageChange} required>
                              <option value="">Select Project Stage</option>
                              <option value="Current">Current</option>
                              <option value="Proposal">Proposal</option>
                            </select>
                            {errors.stage && <div className="text-danger fs-6 mt-2">{errors.stage}</div>}
                          </div>
                          <div className="form-group col-md-6 mt-4">
                            <label htmlFor="sector">Sector <span className='text-danger'>*</span></label>
                            <select className="form-control mt-2" id="sector" name="sector" value={formData.sector} onChange={handleSectorChange} required>
                              <option value="">Select Sector</option>
                              {sectors.map(sector => (
                                <option key={sector.id} value={sector.id}>{sector.name}</option>
                              ))}
                            </select>
                            {errors.sector && <div className="text-danger fs-6 mt-2">{errors.sector}</div>}
                          </div>

                          <div className="form-group col-md-6 mt-4">
                            <label htmlFor="sub_sector">Sub Sector <span className='text-danger'>*</span></label>
                            <select className="form-control mt-2" id="sub_sector" name="sub_sector" value={formData.sub_sector} onChange={handleSubsectorChange} required>
                              <option value="">Select Sub Sector</option>
                              {subsectors.map(subsector => (
                                <option key={subsector.id} value={subsector.name}>{subsector.name}</option>
                              ))}
                            </select>
                            {errors.sub_sector && <div className="text-danger fs-6 mt-2">{errors.sub_sector}</div>}
                          </div>

                          {showTender && (
                            <div className="form-group col-md-6 mt-4">
                              <label htmlFor="tender_reference_number">Tender reference number <span className='text-danger'>*</span></label>
                              <input type="text" className="form-control mt-2" id="tender_reference_number" name="tender_reference_number" value={formData.tender_reference_number} onChange={handleInputChange} required />
                              {errors.tender_reference_number && <div className="text-danger fs-6 mt-2">{errors.tender_reference_number}</div>}
                            </div>
                          )}
                          <div className="form-group col-md-6  mt-4">
                            <label htmlFor="budget">Project Cost <span className='text-danger'>*</span></label>
                            <div class="input-group mt-2">
                              <input type="text" className="form-control" id="budget" name="budget" value={formData?.budget} onChange={handleInputChange} required />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {selectedCurrency || 'Select Currency'}
                                </button>
                                <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                  {currencyCodes.map((code, index) => (
                                    <a
                                      className="dropdown-item"
                                      key={index}
                                      onClick={() => handleSelectCurrency(code)}
                                    >
                                      {code}
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {errors.budget && <div className="text-danger fs-6 mt-2">{errors.budget}</div>}
                          </div>

                          <div className="form-group col-md-6 mt-4">
                            <label htmlFor="deadline">Deadline <span className='text-danger'>*</span></label>
                            <input type="date" className="form-control mt-2" id="deadline" name="deadline" value={formData.deadline} min={today} onChange={handleInputChange} required />
                            {errors.deadline && <div className="text-danger fs-6 mt-2">{errors.deadline}</div>}
                          </div>

                          <div className="form-group col-md-12 mt-4">
                            <label htmlFor="features">Features <span className='text-danger'>*</span></label>
                            <div className="form-floating h-100 mt-2">
                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.features}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFormData({ ...formData, features: data });
                                }}
                                required
                              />
                              {errors.features && <div className="text-danger fs-6 mt-2">{errors.features}</div>}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-5 mb-4">
                          <button type="button" className="btn btn-primary" onClick={addProject}>Next</button>
                        </div>
                      </div>
                    </div>
                    :
                    <>

                      {/* New position structure */}
                      <div className="card mb-4 mt-3">
                        <div className="card-header d-flex justify-content-between align-items-center">Personnel Details
                        </div>
                        <div className="card-body">
                          <div className="accordion mt-4" id="accordionExample">
                            {formData?.key_personnel_details.map((personnel, index) => (
                              <div className="accordion-item mt-4" key={index}>
                                <div className="accordion-header" id={`heading${index}`}>
                                  <button
                                    className="accordion-button d-flex justify-content-between align-items-center"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${index}`}
                                  >
                                    <span>
                                      Personnel {index + 1}
                                    </span>

                                    {index !== 0 && (
                                      <div
                                        className="cursor-pointer bg-white rounded-circle d-flex justify-content-center align-items-center mx-3"
                                        style={{ width: '40px', height: '40px' }}
                                        onClick={() => removePersonnelField(index)}
                                      >
                                        <i
                                          className="material-icons text-danger"
                                          style={{ cursor: 'pointer', color: 'red' }}
                                          title="Delete"
                                        >
                                          delete
                                        </i>
                                      </div>
                                    )}

                                  </button>



                                </div>
                                <div
                                  id={`collapse${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`heading${index}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      <div className='row mt-3'>
                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`title-${index}`}>Job Title <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`title-${index}`} name="title" value={personnel.title} onChange={(e) => handlePersonnelChange(index, 'title', e.target.value)} required />
                                            {errors.key_personnel_details && errors.key_personnel_details.title && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.title}</div>}
                                          </div>
                                        </div>
                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`location-${index}`}>Location <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`location-${index}`} name="location" value={formData.location} onChange={(e) => handlePersonnelChange(index, 'location', e.target.value)} required disabled />
                                            {errors.key_personnel_details && errors.key_personnel_details.location && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.location}</div>}
                                          </div>
                                        </div>
                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`language-${index}`}>Languages <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`language-${index}`} name="language" value={personnel.language} onChange={(e) => handlePersonnelChange(index, 'language', e.target.value)} required />
                                            {errors.key_personnel_details && errors.key_personnel_details.language && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.language}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`stage-${index}`}>Job Stage <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`stage-${index}`} name="stage" value={formData.stage} required disabled />

                                            {/* <select className="form-control mt-2" id={`stage-${index}`} name="stage" value={formData.stage} onChange={(e) => handlePersonnelChange(index, 'stage', e.target.value)} required>
                                              <option value="">Select Job Stage</option>
                                              <option value="Current">Current</option>
                                             <option value="Proposal">Proposal</option>
                                            </select> */}
                                            {errors.key_personnel_details && errors.key_personnel_details.stage && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.stage}</div>}
                                          </div>
                                        </div>


                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`sector-${index}`}>Sector <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`sector-${index}`} name="sector" value={getSectorName(formData.sector)} required disabled />
                                            {/* <select className="form-control mt-2" id={`sector-${index}`} name="sector" value={formData.sector} onChange={(e) => handlePersonnelChange(index, 'sector', e.target.value)} required readonly>
                                              <option value="">Select Sector</option>
                                              {sectors.map(sector => (
                                                <option key={sector.id} value={sector.id}>{sector.name}</option>
                                              ))}
                                            </select> */}
                                            {errors.key_personnel_details && errors.key_personnel_details.sector && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.sector}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`sub_sector-${index}`}>Sub Sector <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`sub_sector-${index}`} name="sub_sector" value={formData.sub_sector} required disabled />
                                            {/* <select className="form-control mt-2" id={`sub_sector-${index}`} name="sub_sector" value={formData.sub_sector} onChange={(e) => handlePersonnelChange(index, 'sub_sector', e.target.value)} required readonly>
                                              <option value="">Select Sub Sector</option>
                                              {subsectors.map(subsector => (
                                                <option key={subsector.id} value={subsector.id}>{subsector.name}</option>
                                              ))}
                                            </select> */}
                                            {errors.key_personnel_details && errors.key_personnel_details.sub_sector && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.sub_sector}</div>}
                                          </div>
                                        </div>


                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`category-${index}`}>Category <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`category-${index}`} name="category" value={formData.category} required disabled />
                                            {/* <select className="form-control mt-2" id={`category-${index}`} name="category" value={formData.category} onChange={(e) => handlePersonnelChange(index, 'category', e.target.value)} required readonly>
                                              <option value="">Select Category</option>
                                              <option value="Goods">Goods</option>
                                              <option value="Works">Works</option>
                                              <option value="Consulting Services">Consulting Services</option>
                                              <option value="Other Non-Consulting Services">Other Non-Consulting Services</option>
                                            </select> */}
                                            {errors.key_personnel_details && errors.key_personnel_details.category && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.category}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`job_type-${index}`}>Job Type <span className='text-danger'>*</span></label>
                                            <select className="form-control mt-2" id={`job_type-${index}`} name="job_type" value={personnel.job_type} onChange={(e) => handlePersonnelChange(index, 'job_type', e.target.value)} required>
                                              <option value="">Select Job Type</option>
                                              <option value="Full-time">Full-time</option>
                                              <option value="Part-time">Part-time</option>
                                              <option value="Contract">Contract</option>
                                              <option value="Permanent">Permanent</option>
                                              <option value="Temporary">Temporary</option>
                                              <option value="Seasonal">Seasonal</option>
                                              <option value="Intermittent">Intermittent</option>
                                              <option value="Internship">Internship</option>

                                            </select>
                                            {errors.key_personnel_details && errors.key_personnel_details.job_type && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.job_type}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`experience-${index}`}>Experience <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control mt-2" id={`experience-${index}`} name="experience" value={personnel.experience} onChange={(e) => handlePersonnelChange(index, 'experience', e.target.value)} required />
                                            {errors.key_personnel_details && errors.key_personnel_details.experience && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.experience}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`deadline-${index}`}>Deadline <span className='text-danger'>*</span></label>
                                            <input type="date" className="form-control mt-2" id={`deadline-${index}`} name="deadline" value={personnel.deadline} onChange={(e) => handlePersonnelChange(index, 'deadline', e.target.value)} min={today} required />
                                            {errors.key_personnel_details && errors.key_personnel_details.deadline && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.deadline}</div>}
                                          </div>
                                        </div>

                                        <div className='col-md-6'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`personnel_type-${index}`}>Personnel Type <span className='text-danger'>*</span></label>
                                            <select className="form-control mt-2" id={`personnel_type-${index}`} name="personnel_type" value={personnel.personnel_type} onChange={(e) => handlePersonnelChange(index, 'personnel_type', e.target.value)} required>
                                              <option value="">Select Personnel Type</option>
                                              <option value="National">National</option>
                                              <option value="International">International</option>
                                            </select>
                                            {errors.key_personnel_details && errors.key_personnel_details.personnel_type && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.personnel_type}</div>}
                                          </div>
                                        </div>

                                        <div className="form-group col-md-6  mt-2">
                                          <label htmlFor={`salary-${index}`}>Salary <span className='text-danger'>*</span></label>
                                          <div class="input-group mt-2">
                                            <input type="text" className="form-control" id={`salary-${index}`} name="salary" value={personnel.salary} onChange={(e) => handlePersonnelChange(index, 'salary', e.target.value)} />
                                            <div className="input-group-append">
                                              <button
                                                className="btn btn-outline-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                disabled
                                              >
                                                {selectedCurrency || 'Select Currency'}
                                              </button>
                                              <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                {currencyCodes.map((code, index) => (
                                                  <a
                                                    className="dropdown-item"
                                                    key={index}
                                                    onClick={() => handleSelectCurrency(code)}
                                                  >
                                                    {code}
                                                  </a>
                                                ))}
                                              </div>
                                            </div>
                                          </div>

                                        </div>

                                        <div className='col-md-12'>
                                          <div className="form-group mt-2">
                                            <label htmlFor={`description-${index}`}>Description <span className='text-danger'>*</span></label>
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={personnel.description}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                handlePersonnelChange(index, 'description', data);
                                              }}
                                            />
                                            {errors.key_personnel_details && errors.key_personnel_details.description && (
                                              <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.description}</div>
                                            )}
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <button type="button" className="btn btn-primary" onClick={addPersonnelField}>
                              Add More Personnel
                            </button>
                          </div>

                        </div>

                      </div>


                      {/* End new position structure */}
                      <div className="text-center mt-5 mb-4">
                        <button type="button" className="btn btn-dark mx-2" onClick={() => setProceedClicked(false)}>Back</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </>
                  }

                </form>

              </div>

            </div>
          </div>
        </>

      }

    </BasicLayout>
  );
};

export default Create;
