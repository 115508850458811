import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Link } from "react-router-dom";
import axios from 'axios';
import { CircularProgress, Grid } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import CustomLoader from 'components/CustomLoader'

function Cover() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const handleSendEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}api/forgot-password`, {
        email: email,
      }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      console.log(response)
      if (response.status === 200) {
        setError("");
      setLoading(false);
      setIsSend(true)
        // setSuccessMessage(response.data.status);
        toast.success(response.data.message, {
          className: "toast-message",
      });
      } else {
      setLoading(false);
        setSuccessMessage('');
        setError(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      setIsSend(true)
      setSuccessMessage('');
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  };


  return (
    <BasicLayout image={bgImage}>
      {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
      <div className="login-wrapper">
        <div className="container">
          <div className="row vh-100 d-flex align-items-center justify-content-center">
            <div className="col-md-8 login-box">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-md-7 p-5">
                <h4 className="text-white">Can't remember your password?</h4>
                <p className="text-white">Provide your registered email ID, and we'll email you a link to reset your password.</p>
                </div>
                <div className="col-md-5 p-4">
                  <div className="login">
                  <h4>Trouble logging in!</h4>
                  <hr></hr>
                  <MDBox component="form" role="form">
                    <MDBox mb={4}>
                      <MDInput
                        type="email"
                        label="Registered Email ID"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {error && (
                        <MDTypography variant="caption" color="error">
                          {error}
                        </MDTypography>
                      )}
                      {successMessage && (
                        <MDTypography variant="caption" color="success">
                          <h6 className="mt-3">{successMessage}</h6>
                          
                        </MDTypography>
                      )}
                    </MDBox>

                    <MDBox mt={2} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleSendEmail}
                      >
                        {isSend ? 'Resend' : 'Send'}
                      </MDButton>
                    </MDBox>

                <MDBox mt={3} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Remembered your password?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Don't have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-up"
                      variant="button"
                      color="success"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign Up
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ToastContainer />
    </BasicLayout>
  );
}

export default Cover;
