import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import ShowShareModal from '../../projects/ShowShareModal';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
const View = () => {
    const [data, setData] = useState([]);
    const [recomProject, setRecomProject] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, selecetdCompanyName] = useState('');
    const [projectId, selecetdProjectId] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [positions, setPositions] = useState([]);
    const [requiredPositions, setRequiredPositions] = useState({});
    const [isReadMore, setIsReadMore] = useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };


    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id) => {
        selecetdCompanyName(name)
        selecetdProjectId(id)
        setShowModal(true)
    }

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/projects/${slug}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setData(result?.project);
            setRecomProject(result?.related_projects);
            console.log("jjk", result)
            const groupedJobs = result?.project?.jobs?.reduce((acc, job) => {
                if (acc[job.personnel_type]) {
                    acc[job.personnel_type].push({ title: job.title, slug: job.slug });
                } else {
                    acc[job.personnel_type] = [{ title: job.title, slug: job.slug }];
                }
                return acc;
            }, {});

            if (groupedJobs) {
                console.log('groupedJobs', groupedJobs)
                setRequiredPositions(groupedJobs)
            }
            
            setPositions(result?.project?.jobs)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const copyURLToClipboard = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL);
        setShowCopyModal(false)
        toast.success('Link copied to clipboard!');
    };

    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : 'N/A';
        return formattedDeadline;
    }

    useEffect(() => {
        fetchData();

    }, [slug, token]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            
            <ShowShareModal
                show={showCopyModal}
                handleClose={() => setShowCopyModal(false)}
                copyURLToClipboard={copyURLToClipboard}
            />


            <div className="container w-100 mb-5">
                <div className={showModal || showCopyModal ? 'blurred-background' : ''}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="card">
                                <div className='card-header bg-secondary'>
                                    <div className="row align-items-center">
                                        <div className="col-md-2">
                                            <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px', backgroundColor: data?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                {data?.company?.logo ? (
                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                ) : (
                                                    <span className="text-uppercase"><strong>{data?.company?.company_name?.charAt(0)}</strong></span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <p className='text-white'><strong>{data?.name}</strong></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row p-3'>
                                    <div className='col-md-9'>
                                        {!token && (
                                            <p className='mb-0'>
                                                <small>
                                                    To access full details and apply for jobs associated with this project, please
                                                    <Link to="/sign-in"> sign in</Link> to your current account or
                                                    <Link to="/sign-up"> sign up</Link> for a free account.
                                                </small>
                                            </p>
                                        )}
                                    </div>

                                    <div className='col-md-3 d-flex justify-content-end align-items-center'>
                                        <button className='btn btn-info me-2' onClick={() => setShowCopyModal(true)}>Share</button>
                                        <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className='card shadow mb-5 bg-white rounded'>
                                        <div className='card-header'><strong>Project Details</strong></div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <h6 className="pt-2"><strong>Posted Date : </strong><span className="text-muted">{convertDate(data?.created_at)}</span></h6>
                                                    <h6 className="pt-2"><strong>Organization: </strong><span className="text-muted">{data?.company?.company_name}</span></h6>
                                                    <h6 className="pt-2"><strong>Sector: </strong><span className="text-muted">{data?.sector}</span></h6>
                                                    <h6 className="pt-2"><strong>Sub Sector: </strong><span className="text-muted">{data?.sub_sector}</span></h6>
                                                    <h6 className="pt-2"><strong>Category: </strong><span className="text-muted">{data?.category}</span></h6>
                                                    <h6 className="pt-2"><strong>Stage: </strong><span className="text-muted">{data?.stage}</span></h6>
                                                    {data?.budget && (
                                                        <h6 className="pt-2"><strong>Project Cost: </strong>
                                                            {token ?
                                                                <span className="text-primary">{data?.budget} {data?.currency}</span>
                                                                :
                                                                <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>
                                                            }
                                                        </h6>
                                                    )}
                                                    <h6 className="pt-2"><strong>Locations: </strong><span className="text-muted">{data?.location}</span></h6>
                                                </div>
                                                <div className='col-md-4 justify-content-end'>
                                                    <h6 className="pt-2"><strong>Deadline: </strong><span className="text-danger">{convertDate(data?.deadline)}</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mt-5 shadow mb-5 bg-white rounded'>
                                        <div className='card-header'><strong>Description</strong></div>
                                        <div className="card-body">
                                            {token ?
                                                <div className='fs-6' dangerouslySetInnerHTML={{ __html: data?.features }}></div>
                                                :
                                                <>
                                                    <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>

                                                    <span className="text-secondary mx-2">
                                                        Please <Link to="/sign-in">sign in</Link> or{' '}
                                                        <Link to="/sign-up">create a free account</Link> to see this description.
                                                    </span>
                                                </>

                                            }
                                        </div>
                                    </div>
                                    {data?.company?.about && (
                                        <>
                                            <div className='card mt-5 shadow mb-5 bg-white rounded'>
                                                <div className='card-header'><strong>About Organization</strong></div>
                                                <div className="card-body">
                                                    <div className='fs-6'>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: isReadMore
                                                                ? data?.company?.about
                                                                : data?.company?.about?.length > 300
                                                                    ? `${data?.company?.about.slice(0, 300)}...`
                                                                    : data?.company?.about,
                                                        }}></div>
                                                        {data?.company?.about?.length > 300 && (
                                                            <button className="btn btn-link p-0" onClick={toggleReadMore}>
                                                                {isReadMore ? 'Read Less' : 'Read More'}
                                                            </button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )}
                                    <div className='card mb-5 bg-white rounded'>
                                        <div className='card-header'><strong>Required Positions</strong></div>
                                        <div className="card-body">
                                            {requiredPositions && Object.entries(requiredPositions).map(([personnelType, jobs]) => (
                                                <div className='card shadow mb-5 bg-white rounded p-3' key={personnelType}>
                                                    <h6 className="pt-2"><strong>Personnel Type : {personnelType}</strong></h6>
                                                    <h6 className="text-muted">
                                                        {jobs.map((job, index) => (
                                                            <span key={job.slug}>
                                                                <Link to={`/job/${job.slug}`}>{job.title}</Link>
                                                                {index < jobs.length - 1 && ',   '}
                                                            </span>
                                                        ))}
                                                    </h6>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* {data.jd_image && (
                                        <div className="view view-cascade mt-3 mb-3">
                                            <img className="card-img-top" src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data.jd_image}`} alt="Card image cap" />
                                            <a href="#!">
                                                <div className="mask rgba-white-slight"></div>
                                            </a>
                                        </div>
                                    )} */}
                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <h3 className='text-success text-center'>Releated projects for you</h3>
                            {recomProject.length > 0 ?
                                <>
                                    {recomProject.map((project, index) => (
                                        <>
                                            <div className="col-md-12 py-3" key={index}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-2">
                                                                <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px', backgroundColor: project?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                                    {project?.company?.logo ? (
                                                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${project?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    ) : (
                                                                        <span className="text-uppercase"><strong>{project?.company?.company_name?.charAt(0)}</strong></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <Link to={`/project/${project?.slug}`}>
                                                                    <h5 className="card-title text-primary"><strong>{project?.name}</strong></h5>
                                                                </Link>
                                                                <h6 className='text-truncate'>{project?.company?.company_name}</h6>

                                                            </div>

                                                        </div>
                                                        <div className='my-3 d-flex justify-content-between align-items-center'>
                                                            {convertDate(project?.deadline) && (
                                                                <div>
                                                                    <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                    <span className="fs-6 text-muted"> Closing on  {convertDate(project?.deadline)}</span>
                                                                </div>
                                                            )}

                                                            <div className='px-3'>
                                                                <i className="material-icons fs-6" title={project?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                >info</i>
                                                                <span className="fs-6 text-muted"> {project?.stage}</span>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div className='mx-2'>
                                                            <div className='row mb-3'>
                                                                <div className="col-md-6 pt-2 ">
                                                                    <h6><strong>Sub Sector</strong></h6>
                                                                    <h6 className="text-muted">{project?.sub_sector}</h6>
                                                                </div>
                                                                <div className="col-md-6 pt-2 float-end">
                                                                    <h6><strong>Location</strong></h6>
                                                                    <h6 className="text-muted">{project?.location}</h6>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </>
                                    ))}
                                </>
                                :
                                <>
                                    <div className="col-md-12 mt-5">
                                        <div className="card-shadow">
                                            <p className='text-center'>
                                                <span className="material-symbols-outlined fs-1">
                                                    sentiment_dissatisfied
                                                </span>
                                            </p>
                                            <h5 className='text-center'>No Releated projects found </h5>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                </div>

            </div>

            <Footer />
        </DashboardLayout>
    );
};

export default View;
