import React, { useEffect, useState } from 'react';
import Footer from "examples/Footer";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

const PostedJobs = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { token, user } = useSelector(selectAuth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/posted-jobs`, {
          method: 'GET', // Adjust the HTTP method as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setData(result);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApprove = async (projectId, message) => {
    // Show a confirmation dialog
    setShowAlert(false)
    const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this job?' : 'Are you sure you want to draft this job?');

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}api/job/${projectId}/update-status`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (response.ok) {
          fetchData();
          setShowAlert(true);
          console.log("result", result)
          toast.success(result?.message);


        } else {
          // Handle error, e.g., show an error message
          console.error('Failed to approve job');
        }
      } catch (error) {
        // Handle network error
        console.error('Network error:', error);
      }
    }
  };

  const handleDelete = async (projectId) => {
    const confirmed = window.confirm('Are you sure you want to delete this job?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      const response = await fetch(`${apiUrl}api/projects/${projectId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setAlertMessage('Project details deleted successfully');
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete job');
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      setShowAlert(true);
      setError('Failed to delete job. Please try again later.');
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);
  return (
    <BasicLayout>
      <ToastContainer />
      <div className='inner-banner'>
        <div className='container'>
          <h2>Posted Jobs</h2>
        </div>
      </div>
          <div className="container posted-job-listing mt-2">
            <div className="row justify-content-center">
          
              {data?.map((job, index) => (
                <div className='col-md-12 py-3 px-3' key={index}>
                  <div className="card posted-list">

                    <div className="card-header d-flex justify-content-between align-items-center text-primary text-wrap">
                      <Link to={`/job/${job?.slug}`}>
                        <h5 className="m-0" style={{ cursor: 'pointer' }}>{job?.title}</h5>
                      </Link>
                      <div className="cursor-pointer">
                        
                      </div>
                    </div>
                    <div className="card-body">
                      <div className='meta-info'>
                      <div className="row">
                        <div className="col-md-2">
                          <div>
                            <label>Sectors</label>
                            <p className='text-wrap'>{job?.sector}</p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <label>Sub Sectors</label>
                            <p className='text-wrap'>{job?.sub_sector}</p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <label>Location</label>
                            <p className='text-wrap'>{job?.location}</p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <label>Project Status</label>
                            <p className='text-wrap'>{job.is_approved === 0 ? 'Inactive' : 'Active'}</p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <label>Applications</label>
                            <p className='text-wrap'>{job?.applications.length || 0}</p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <label>Posted on</label>
                            <p className='text-wrap'>{formatDistanceToNow(new Date(job?.created_at))} ago </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className='col-md-6 ms-auto action-buttons text-end'>
                        <Link to={`/edit-job/${job?.slug}`} className='btn btn-primary me-2'>
                          <i className="material-icons-outlined" style={{ cursor: 'pointer' }} title="Edit">edit</i>
                        </Link>

                        {job.is_approved === 0 ? (
                          <button type="button" className="btn btn-success" title='Publish' onClick={() => handleApprove(job.id, 'publish')}><i className="material-icons ml-2">publish</i></button>
                        ) : (
                          <button type="button" className="btn btn-secondary" title='Draft' onClick={() => handleApprove(job.id, 'draft')}><i className="material-icons ml-2">unpublished</i></button>
                        )}
                        <Link to={`/job/${job?.slug}/matched-professionals`} title='View Matched Professionals' className="btn btn-dark mx-3"><i className="material-icons ml-2">diversity_1</i></Link>
                        <Link to={`/job/${job?.slug}/applicants`} title="View Applicants" className="btn btn-info"><i className="material-icons ml-2">groups</i></Link>
                        <Link to={`/job/${job?.slug}`} title="View Details" className="btn btn-primary mx-3"><i className="material-icons ml-2">visibility</i></Link>
                      </div>

                      </div>
                      </div>
                      
                      


                    </div>
                  </div>
                </div>

              ))}
            </div>

          </div>
    </BasicLayout>
  )
}

export default PostedJobs