import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import MDButton from 'components/MDButton';
const currencyCodes = [
    "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];
const Create = () => {
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const { token, user } = useSelector(selectAuth);
    const [showAlert, setShowAlert] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedSubsector, setSelectedSubsector] = useState(null);
    const [subsectors, setSubsectors] = useState([]);
    const [showTender, setShowTender] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('INR');

    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
    const navigate = useNavigate()

    // State variables to store form data
    const [formData, setFormData] = useState({
        title: '',
        category: '',
        sector: '',
        sub_sector: '',
        stage: '',
        experience: '',
        deadline: '',
        location: '',
        language: '',
        salary: '',
        job_type: '',
        personnel_type: '',
        description: '',
        currency: ''
    });

    const handleSelectCurrency = (code) => {
        setSelectedCurrency(code);
        setFormData({ ...formData, currency: code });
    };
    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, type } = e.target;
        const { value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Function to handle input changes in personnel field
    // const handlePersonnelChange = (index, value) => {
    //   const updatedPersonnel = [...formData.key_personnel_details];
    //   updatedPersonnel[index].name = value;
    //   setFormData({
    //     ...formData,
    //     key_personnel_details: updatedPersonnel
    //   });
    // };


    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}api/job`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate('/all-jobs');
        } catch (error) {
            console.error('Error creating project:', error);
            // Handle error
            setError('Error creating project. Please try again.');
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorId = event.target.value;
        setSelectedSector(sectorId);
        setFormData({ ...formData, sector: sectorId });

        const sector = sectors.find(s => s.id === parseInt(sectorId));
        if (sector) {
            setSubsectors(sector.children);
        }
    };

    const handleSubsectorChange = (event) => {
        const subsectorId = event.target.value;
        setSelectedSubsector(subsectorId); // Set the selected subsector state
        setFormData({ ...formData, sub_sector: subsectorId }); // Update formData.sub_sector
    };

    const handleStageChange = (event) => {
        const stageValue = event.target.value;
        setShowTender(stageValue === 'Proposal');
        setFormData({ ...formData, stage: stageValue }); // Update formData.sub_sector
    };

    useEffect(() => {
        fetchData();

    }, [token]);

    return (
        <BasicLayout>
            {user && user?.user?.is_approved == 0 ?
                <>
                    <div className="vh-100 d-flex justify-content-center align-items-center">
                        <div>
                            <div className="mb-4 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-warning bi bi-emoji-frown-fill" width="75" height="75" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0Zm3.176 11.03a.5.5 0 0 1-.85.53A3.498 3.498 0 0 0 8 9.5a3.498 3.498 0 0 0-2.326 2.06.5.5 0 1 1-.85-.53A4.498 4.498 0 0 1 8 8.5a4.498 4.498 0 0 1 3.176 2.53ZM5.5 6a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm6 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                </svg>

                            </div>
                            <div className="text-center">
                                <h1>Account Not Approved</h1>
                                <p className='mb-2'>  Your account approval is still pending. You will be able to add jobs once it has been approved. Please contact the admin for further assistance if necessary.</p>
                                <MDButton variant="gradient" color="info" onClick={() => navigate('/support')}>
                                    Contact Admin
                                </MDButton>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="container mt-5 py-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className='d-flex justify-content-between align-items-center mt-3 mb-2 p-3'>
                                        <h3 className='text-center'>Add New Job</h3>
                                        <button className='btn btn-dark' onClick={() => navigate(-1)}>
                                            Back
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-end mb-3">
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row mt-3'>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="title">Job Title <span className='text-danger'>*</span></label>
                                                    <input type="text" className="form-control mt-2" id="title" name="title" value={formData.title} onChange={handleInputChange} required />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="location">Location <span className='text-danger'>*</span></label>
                                                    <input type="text" className="form-control mt-2" id="location" name="location" value={formData.location} onChange={handleInputChange} required />
                                                </div>

                                                <div className="form-group col-md-6 mt-4">
                                                    <label htmlFor="language">Languages <span className='text-danger'>*</span></label>
                                                    <input type="text" className="form-control mt-2" id="language" name="language" value={formData.language} onChange={handleInputChange} required />
                                                </div>

                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="stage">Job Stage <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="stage" name="stage" value={formData.stage} onChange={handleStageChange} required>
                                                        <option value="">Select Job Stage</option>
                                                        <option value="Current">Current</option>
                                                        <option value="Proposal">Proposal</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="type">Sector <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="type" name="type" value={formData.sector} onChange={handleSectorChange} required>
                                                        <option value="">Select Sector</option>
                                                        {sectors.map(sector => (
                                                            <option key={sector.id} value={sector.id}>{sector.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="type">Sub Sector <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="type" name="type" value={formData.sub_sector} onChange={handleSubsectorChange} required>
                                                        <option value="">Select Sub Sector</option>
                                                        {subsectors.map(subsector => (
                                                            <option key={subsector.id} value={subsector.name}>{subsector.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-6 mt-4">
                                                    <label htmlFor="category">Category <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="category" name="category" value={formData?.category} onChange={handleInputChange} required>
                                                        <option value="">Select Category</option>
                                                        <option value='Goods'>Goods</option>
                                                        <option value='Works'>Works</option>
                                                        <option value='Consulting Services'>Consulting Services</option>
                                                        <option value='Other Non-Consulting Services'>Other Non-Consulting Services</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="job_type">Position Type <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="job_type" name="job_type" value={formData.job_type} onChange={handleInputChange} required>
                                                        <option value="">Select Position Type</option>
                                                        <option value="Full-time">Full-time</option>
                                                        <option value="Part-time">Part-time</option>
                                                        <option value="Contract">Contract</option>
                                                        <option value="Permanent">Permanent</option>
                                                        <option value="Temporary">Temporary</option>
                                                        <option value="Seasonal">Seasonal</option>
                                                        <option value="Intermittent">Intermittent</option>
                                                        <option value="Internship">Internship</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-6 mt-4">
                                                    <label htmlFor="experience">Experience  <span className='text-danger'>*</span></label>
                                                    <input type="text" className="form-control mt-2" id="experience" name="experience" value={formData.experience} onChange={handleInputChange} required />
                                                </div>

                                                <div className="form-group col-md-6 mt-4">
                                                    <label htmlFor="deadline">Deadline <span className='text-danger'>*</span></label>
                                                    <input type="date" className="form-control mt-2" id="deadline" name="deadline" value={formData.deadline} min={today} onChange={handleInputChange} required />
                                                </div>

                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="personnel_type">Personnel Type <span className='text-danger'>*</span></label>
                                                    <select className="form-control mt-2" id="personnel_type" name="personnel_type" value={formData.personnel_type} onChange={handleInputChange} required>
                                                        <option value="">Select Personnel Type</option>
                                                        <option value="National">National</option>
                                                        <option value="International">International</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6  mt-4">
                                                    <label htmlFor="salary">Salary <span className='text-danger'>*</span></label>
                                                    <div class="input-group mt-2">
                                                        <input type="text" className="form-control" id="salary" name="salary" value={formData?.salary} onChange={handleInputChange} required />
                                                        <div className="input-group-append">
                                                            <button
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                {selectedCurrency || 'Select Currency'}
                                                            </button>
                                                            <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                                {currencyCodes.map((code, index) => (
                                                                    <a
                                                                        className="dropdown-item"
                                                                        key={index}
                                                                        onClick={() => handleSelectCurrency(code)}
                                                                    >
                                                                        {code}
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-group col-md-12 mt-4">
                                                    <label htmlFor="floatingTextarea2">Job Description <span className='text-danger'>*</span></label>
                                                    <div className="form-floating h-100 mt-2">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={formData.description}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setFormData({ ...formData, description: data });
                                                            }}
                                                            required

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-5 mb-4">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </BasicLayout>
    );
};

export default Create;
