import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDButton from 'components/MDButton';
const View = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token } = useSelector(selectAuth);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`${apiUrl}api/employers/view/${id}`, {
                method: 'GET', // Adjust the HTTP method as needed
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }
            const data = await response.json();
            setUser(data.user);
            setUserDetails(data.userDetails);

        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3">
                                    <MDButton variant="gradient" color="dark" onClick={() => navigate('/employers')}
                                    >
                                        Back
                                    </MDButton>
                                </div>
                                {user && (
                                    <div className="user-details">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p><strong>Name:</strong> {user.name}</p>
                                                <p><strong>Email:</strong> {user.email}</p>
                                                {userDetails && (
                                                    <>
                                                        <p><strong>Phone Number:</strong> {user?.phone}</p>
                                                        <p><strong>Company Name:</strong> {userDetails.company_name}</p>
                                                        {userDetails.registration_certificate &&
                                                            (
                                                                <p>
                                                                    <strong>Registration Certificate:</strong>&nbsp;
                                                                    <a href={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${userDetails.registration_certificate}`} target="_blank" rel="noopener noreferrer">View</a>
                                                                </p>
                                                            )}

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default View;
