import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
const EditCVModal = ({ show, handleClose, data, token, handleDataFetch }) => {
    const [resume, setResume] = useState('');
    const [resumeChecked, setResumeChecked] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        resume_attachment: null,
    });
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type !== 'application/pdf' && 
                file.type !== 'application/msword' && 
                file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                setError('Please upload a valid PDF/Doc/Docx file.');
                setResume('');
                return;
            }

            if (file.size > 2 * 1024 * 1024) {
                setError('File size exceeds 2 MB limit.');
                setResume('');
                return;
            }
            setFormData({ ...formData, resume_attachment: e.target.files[0] });
            setError('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!resume) {
            setError('Please upload your CV.');
            return;
        }
        try {
            const id = data?.user?.id;
            if (id) {
                // const formData = new FormData();
                // console.log('resume',resume.file)
                // if (resume) {
                // formData.append('resume_attachment', resume);
                // console.log("formData", formData)
                // console.log("resume_attachment", resume)
                // }
                const response = await axios.post(`${apiUrl}api/professional/update-cv/${id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                });
                console.log(response.data);
                toast.success("CV updated successfully", {
                    className: "toast-message",
                });
                handleClose();
                handleDataFetch();
            }
        } catch (error) {
            console.error('Error updating resume', error);
        }
    };
    const downloadPDF = () => {
        // Retrieve the URL of the PDF file from formData.resume_attachment
        const pdfUrl = `${apiUrl}${node === 'production' ? 'public/' : ''}storage/` + data?.resume_attachment;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'resume.pdf'; // Set the filename for download

        // Append the anchor element to the body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };
    useEffect(() => {
        setResume(data?.details?.resume_attachment || '');
    }, [data]);

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Upload CV</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true" className='p-2'>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                {resume ?
                                    <>
                                        <div className="input-group mt-4 mb-3">
                                            <button className="btn btn-danger" type="button" id="button-addon1" onClick={() => setResumeChecked(!resumeChecked)}>PDF</button>
                                            <input type="text" className="form-control" value={resume.substring(resume.indexOf('/') + 1)}
                                                aria-label="Recipient's username" aria-describedby="button-addon2" readOnly />
                                            <span className="input-group-text" onClick={downloadPDF}><i className="material-icons" style={{ cursor: 'pointer', fontSize: '1.5rem' }} title="Download">download</i></span>
                                            <div className="input-group-text">
                                                <input
                                                    className="form-check-input mt-0"
                                                    type="checkbox"
                                                    aria-label="Radio button for following text input"
                                                    checked={resumeChecked}
                                                    onChange={() => setResumeChecked(!resumeChecked)}
                                                />
                                            </div>
                                        </div>
                                        {!resumeChecked && (
                                            <div className="form-group mt-4">
                                                <label htmlFor="resume_attachment">Upload CV </label>
                                                <input type="file" className="form-control mt-2" id="resume_attachment" accept=".pdf,.doc,.docx" onChange={handleChange} name="resume_attachment" />
                                            </div>
                                        )}
                                    </>
                                    :
                                    <div className="form-group mt-4 mb-3">
                                        <label htmlFor="resume_attachment">Upload CV <span className='text-danger'>*</span></label>
                                        <input type="file" className="form-control" id="resume_attachment" accept=".pdf,.doc,.docx" onChange={handleChange} name="resume_attachment" required />
                                        {error && <span className="text-danger">{error}</span>}
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditCVModal