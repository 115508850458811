import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';
import '../../assets/css/ajax-loader.gif';
import '../../assets/css/owl.video.play.png';
import '../../assets/css/customize-proxtal.css';
import '../../assets/css/owl.carousel.min.css';
import 'animate.css';
import '../../assets/css/owl.theme.default.css';
import MapImage from "../../assets/images/world-map.png";
import bannerImage from "../../assets/images/banner-img-right.png";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomLoader from 'components/CustomLoader';

const Index = () => {
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [allDesignation, setAllDesignation] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [designation, setDesignation] = useState('');
  const [experience, setExperience] = useState('');
  const [location, setLocation] = useState('');
  const [allLocations, setAllLocations] = useState([]);
  const { token } = useSelector(selectAuth);
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

  const fetchProjectJobs = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}api/get-project-jobs`, {
        method: 'GET', // Adjust the HTTP method as needed
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setData(result?.projects);
      setJobs(result?.jobs);
      setAllDesignation(result?.designations);
      setAllLocations(result?.locations);
      setSectors(result?.sectors);
      console.log('allDesignation', allDesignation)
      setTimeout(() => {
        setLoading(false);
      }, 2000); // 1 second delay

    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 1 second delay
      console.error('Error fetching job data:', error);
    }
  };

  const handleSectorChange = (event) => {
    const sectorName = event.target.value;
    setSelectedSector(sectorName);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setDesignation(value);

    if (value.length > 0) {
      const filtered = allDesignation.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDesignations(filtered);
    } else {
      setFilteredDesignations([]);
    }
  };

  const handleSelectDesignation = (selected) => {
    setDesignation(selected);
    setFilteredDesignations([]); // Hide suggestions after selection
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setLocation(value);

    if (value.length > 0) {
      const filtered = allLocations.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLocations(filtered);
    } else {
      setFilteredLocations([]);
    }
  };
  const handleSelectLocation = (selected) => {
    setLocation(selected);
    setFilteredLocations([]); // Hide suggestions after selection
  };

  const getSearchResult = async () => {
    try {
      const searchData = {
        designation,
        selectedSector,
        // experience,
        location,
      };
      const queryParams = new URLSearchParams(searchData).toString();
      // window.location.href = searchQuery;
      navigate(`/all-jobs?${queryParams}`, { replace: true });
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    fetchProjectJobs();
  }, []);
  return (
    <BasicLayout>
      {loading && (
        <div className="spinner-overlay">
          <CustomLoader />
        </div>
      )}
      {/* Steps */}
      <div className='banner-wrapper animate__animated animate__fadeInUp'>
        <div class="container">
          <div class="row d-blck d-md-flex align-items-center justify-content-center">
            <div class="col-md-9 home-search">
              <h2 className='text-center mb-3 mt-5'>Find your dream job now</h2>
              <div className="d-block d-md-flex rounded shadow p-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter designation"
                    aria-label="Skills"
                    value={designation}
                    onChange={handleInputChange}
                  />
                  {filteredDesignations.length > 0 && (
                    <ul className="list-group select-position position-absolute list-unstyled overflow-auto" style={{ maxHeight: '300px' }} >
                      {filteredDesignations.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item list-group-item-action"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSelectDesignation(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className="input-group-prepend">
                    <button
                      className="btn select-sector dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedSector || 'Select sector'}
                    </button>
                    <div className="dropdown-menu select-sector">
                      {sectors.map((sectorItem) => (
                        <a
                          className="dropdown-item"
                          key={sectorItem.name}
                          onClick={() => setSelectedSector(sectorItem.name)}
                          style={{ cursor: 'pointer' }}
                        >
                          {sectorItem.name}
                        </a>
                      ))}
                    </div>
                  </div>

                  {/* <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle border-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {experience || 'Select experience'}
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('0-2')}>0-2 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('2-5')}>2-5 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('5-10')}>5-10 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('10+')}>10+ Years</a>
                    </div>
                  </div> */}

                  <div className="input-group-prepend">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Location"
                      aria-label="Location"
                      value={location}
                      onChange={handleLocationChange}
                    />
                    {filteredLocations.length > 0 && (
                      <ul className="list-group select-location position-absolute list-unstyled overflow-auto" style={{ maxHeight: '400px' }}>
                        {filteredLocations.map((item, index) => (
                          <li
                            key={index}
                            className="list-group-item list-group-item-action"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSelectLocation(item)}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}

                  </div>

                  <div className="input-group-append">
                    <button
                      className="btn btn-warning search-btn"
                      type="button"
                      onClick={getSearchResult}
                    >
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 banner-content text-center">
              <h2>Unlock the Power of Collaboration</h2>
              <h3>A User-Friendly Platform<br></br> Matching International Development Professionals with<br></br>#Projects</h3>
              {!token && (
                <Link to={'/sign-up-as-professional'} class="btn btn-warning mt-2">Register as a professional</Link>
              )}
            </div>
            {/* <div class="col-md-6">
              <img src={bannerImage} alt="" />
            </div> */}
          </div>
        </div>

        {/* <div class="scroll-downs">
          <div class="mousey">
            <div class="scroller"></div>
          </div>
        </div> */}

      </div> {/* banner area end */}

      <div className="job-category-home container mt-5">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4></h4>
            <h2>Top Categories</h2>
            {/* <h3>and Get your Dream Job</h3> */}
          </div>
        </div>
        <div className="category-list">
          <div className="row d-flex align-items-stretch">
            {sectors?.map((sector, index) => (
              <div className="col-md-2 col-6 g-3" key={sector?.id}>
                <div class="card shadow h-100">
                  <div class="card-body align-items-center d-flex p-3">
                    <div className="catlist">
                      <h2>{sector?.name}</h2>
                      <p>{sector?.projects_count} Project Posted</p>
                    </div>
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>

      </div> {/* category */}

      {/* latest project */}
      <div className="container featured-projects mt-5">
        <div className="row d-flex align-items-center mt-4">
          <div className="col-xl-6">
            <div className="title-wrapper text-left">
              <h2>Featured Projects</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {data?.map((project, index) => (
            <div className="col-md-4 gx-5 mb-3" key={project?.id}>
              <div className="featured-project">
                <div className="company-info">
                  <div className="logo"><div className="alphabates">{project?.company?.company_name?.charAt(0)}</div></div>
                  <div className="info">
                    <h2 className='text-wrap'>{project?.name}</h2>
                    {/* <p className="people-required">10 no. of Civil Engineer</p> */}
                  </div>
                </div>
                {project?.features && (
                  <>
                    <div className="short-desc">
                      <p><span dangerouslySetInnerHTML={{ __html: project.features.substring(0, 80) + "..." }} /></p>
                    </div>
                  </>
                )}
                <Link to={`/project/${project?.slug}`} className="btn btn-outline mt-3">
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>

      </div>

      {/* latest jobs */}
      <div className="container featured-projects mt-5">
        <div className="row d-flex align-items-center mt-4">
          <div className="col-xl-6">
            <div className="title-wrapper text-left">
              <h2>Featured Jobs</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {jobs?.map((job, index) => (
            <div className="col-md-4 gx-5 mb-3" key={job?.id}>
              <div className="featured-project">
                <div className="company-info">
                  <div className="logo"><div className="alphabates">{job?.company?.company_name?.charAt(0)}</div></div>
                  <div className="info">
                    <h2 className='text-wrap'>{job?.title}</h2>
                    {/* <p className="people-required">10 no. of Civil Engineer</p> */}
                  </div>
                </div>
                {job?.description && (
                  <>
                    <div className="short-desc">
                      <p><span dangerouslySetInnerHTML={{ __html: job.description.substring(0, 100) + "..." }} /></p>
                    </div>
                  </>
                )}
                <Link to={`/job/${job?.slug}`} className="btn btn-outline mt-3">
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>

      </div>

      <div className="container easy-steps mt-5 py-5">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4>Are you Looking for a Job ?</h4>
            <h2>Follow Easy Steps</h2>
            <h3>and Get your Dream Job</h3>
          </div>

        </div>
        <div className="row d-flex justify-align-center align-contents-center">
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> assignment </span></div>
              <h2>Get Register</h2>
              <p>Join now and unlock endless career opportunities.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> upload_file </span></div>
              <h2>Update CV</h2>
              <p>Showcase your skills—upload your CV to attract top employers.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> manage_search </span></div>
              <h2>Serch Jobs</h2>
              <p>Discover your ideal job from thousands of listings.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> stacked_email </span></div>
              <h2>Apply For the Jobs</h2>
              <p>Take the next step—apply now and advance your career.</p>
            </div>
          </div>
        </div>

      </div>

      {/* Call to action area */}
      <div className="call-to-action-1">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-5 image-area text-end pe-5">
              <img src={MapImage} alt="" />
            </div>
            <div className="col-md-7 content-area">
              <h2>Unlock Your Dream Career Today!</h2>
              <p>Join thousands of professionals who have found their path to success with us. Register now and take the next step in your career journey!</p>
              {!token && (
                <Link to="/sign-up-as-professional" className="btn btn-warning mt-3">
                  Register Now
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* recent jobs */}


      {/* partners */}
      {/* <div className="partners container">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4>Are you Looking for a Job ?</h4>
            <h2>Follow Easy Steps</h2>
            <h3>and Get your Dream Job</h3>
          </div>

        </div>

        <div className="owl-carousel" id="partner-carousel">
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
        </div>
      </div> */}
    </BasicLayout>
  )
}

export default Index