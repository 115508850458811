import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MDInput from "components/MDInput";
import MDBox from 'components/MDBox';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';


const EditCertification = ({ data, user, token, handleDataFetch }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [certification, setCertification] = useState([]);
    const [showCertificationModal, setShowCertificationModal] = useState(false);
    const [selectedCertification, setSelectedCertification] = useState(''); // State to hold the selected certification for editing
    const [error, setError] = useState(false);
    const [dateError, setDateError] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const initialState = {
        name: '',
        organization: '',
        credential_id: '',
        credential_url: '',
        description: '',
        start_date: '2024-01',
        end_date: '',
    };

    const [formData, setFormData] = useState(initialState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("selectedCertification", selectedCertification)

        // Construct new certification object
        const newCertification = {
            name: formData.name,
            organization: formData.organization,
            credential_id: formData.credential_id,
            credential_url: formData.credential_url,
            start_date: formData.start_date,
            end_date: formData.end_date,
            id: selectedCertification
        };

        try {
            const id = data?.user?.id;
            // Make the API call to update certification details
            const response = await axios.post(`${apiUrl}api/professional/add-certification/${id}`, { certification: [newCertification] }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            toast.success("Certification details saved successfully !!", {
                className: "toast-message",
            });
            // Close modal
            setShowCertificationModal(false);
            handleDataFetch();

        } catch (error) {
            console.error('Error updating certification details:', error);
        }
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const isPresentValue = name === 'currently_working' ? checked : formData.isPresent;

        setFormData({ ...formData, [name]: newValue, isPresent: isPresentValue });
        let errorMessage = '';
        if (name === 'start_date' || name === 'end_date') {
            const startDate = name === 'start_date' ? new Date(value) : new Date(formData.start_date);
            const endDate = name === 'end_date' ? new Date(value) : new Date(formData.end_date);
            const currentDate = new Date();

            if (startDate > currentDate) {
                setError(true);
                errorMessage = 'Issue date cannot be in the future.';
                setDateError(errorMessage)
            }
            else if (startDate > endDate) {
                setError(true);
                errorMessage = 'Issue date should not be after expiration date.';
                setDateError(errorMessage)
            }
            else {
                errorMessage = '';
                setError(false);
            }
            console.log("errorMessage", errorMessage);
        }

    }
    function convertDateFormat(dateString) {
        if (dateString) {
            const dateParts = dateString.split('-');
            const year = dateParts[0];
            const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
            return `${month} ${year}`;
        }

    }

    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    const openAddModal = () => {
        setFormData(initialState);
        setShowCertificationModal(true);

    }

    const handleEdit = (index) => {
        setSelectedCertification(index);
        setFormData({ ...certification[index] });
        setShowCertificationModal(true);
    }

    const handleDelete = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this certification?");
        if (confirmDelete) {
            deleteCertification(index);
        }
    }

    const deleteCertification = async (CertificationId) => {
        const id = data?.user?.id;

        const response = await axios.delete(`${apiUrl}api/professional/delete-certification/${id}/${CertificationId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        // Handle success response
        toast.success("Certification details deleted successfully !!", {
            className: "toast-message",
        });

        // After successful deletion, you might want to update the UI or refetch data
        handleDataFetch();

    }


    useEffect(() => {
        setCertification(data?.details?.certification);
    }, [data]);

    return (
        <div className="card mb-4 mt-3">
            {/* Card Header */}
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5>Licenses & certifications</h5>
                {data?.user?.id == user?.user?.id && (
                    <div className="cursor-pointer" onClick={openAddModal}>
                        <i className="material-icons" style={{ cursor: 'pointer' }} title="Add">add</i>
                    </div>
                )}
            </div>

            {/* Card Body */}
            {data?.details?.certification && data?.details?.certification.length > 0 ? (

                <div className="card-body">
                    <div className="accordion" id="accordionCertification">
                        {data?.details?.certification.map((cert, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={`cert-heading${index}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#cert-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                        {cert.name} - {cert.organization}
                                    </button>

                                </h2>
                                <div id={`cert-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`cert-heading${index}`} data-bs-parent="#accordionCertification">
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <div className='meta-info'>
                                            {cert?.credential_url && (
                                                <Link to={cert?.credential_url} target="_blank">
                                                    <h5>{cert.name} <span class="material-symbols-outlined"> link </span></h5>
                                                </Link>
                                            )}
                                            <h6>{cert?.organization}</h6>
                                            {cert?.start_date && (
                                                <p><strong>Issued :</strong> {convertDateFormat(cert?.start_date)}</p>
                                            )}
                                            {cert?.end_date && (
                                                <p><strong>Expired :</strong> {convertDateFormat(cert?.end_date)}</p>
                                            )}

                                            {cert?.credential_id && (
                                                <p><strong>Credential ID:</strong> <span>{cert?.credential_id}</span></p>
                                            )}

                                            {cert?.credential_url && (
                                                <a class="btn-more" href={cert?.credential_url} role="button" target="_blank">Show Credential </a>
                                            )}
                                            {cert?.description && (
                                                <p className='text-muted pt-3'>
                                                    <small>{showFullDescription ?
                                                        <span dangerouslySetInnerHTML={{ __html: cert?.description }} /> :
                                                        <span dangerouslySetInnerHTML={{ __html: cert?.description.substring(0, 200) + "..." }} />
                                                    }
                                                        {cert?.description.length > 200 && (
                                                            <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                {showFullDescription ? " View less" : " View more"}
                                                            </span>
                                                        )}
                                                    </small>
                                                </p>
                                            )}
                                        </div>
                                        <div className='buttons'>
                                            <span className="edit-btn" onClick={() => handleEdit(index)}>
                                                <span class="material-symbols-outlined"> edit </span>
                                            </span>
                                            <span className="delete-btn" onClick={() => handleDelete(index)}>
                                                <span class="material-symbols-outlined"> delete </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>

            ) : (
                <div className="card-body">
                    <p className='text-muted'>
                        <small>Showcase your expertise and stand out to employers by adding your Licenses & Certifications! Highlight your achievements and boost your profile today.</small>
                    </p>
                </div>
            )}

            {/* Modal */}
            <div className={`modal ${showCertificationModal ? 'show' : ''}`} style={{ display: showCertificationModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add certification</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowCertificationModal(false)}>
                                <span aria-hidden="true" className='p-2'>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body w-100 h-100">
                            <form onSubmit={handleSubmit}>
                                {/* Form Fields */}
                                <div className="form-group">
                                    <label htmlFor="name">Name <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" placeholder="Ex: Smart English Basics for Professionals" id="name" name="name" required onChange={handleChange} value={formData.name} maxLength={150} />
                                </div>
                                <div className="form-group mt-4">
                                    <label htmlFor="organization">Issuing organization <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" placeholder="Ex: Great Learning" id="organization" name="organization" onChange={handleChange} value={formData.organization} maxLength={150} required />
                                </div>




                                <div className="form-group mt-4">
                                    <div className='row mt-4 mb-4'>
                                        <div className='col-md-6'>
                                            <label htmlFor="start_date" className="form-label">Issue date </label>
                                            <MDInput type="month" id="start_date" name="start_date" onChange={handleChange} value={formData.start_date} />
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor="end_date" className="form-label">Expiration Date </label>
                                            <MDInput type="month" id="end_date" name="end_date" onChange={handleChange} value={formData.end_date} />
                                        </div>
                                    </div>
                                    {error && dateError && <small className="text-danger"><small>{dateError}</small></small>}
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="credential_id">Credential ID</label>
                                    <input type="text" className="form-control" placeholder="Ex: Design and Applied Arts" id="credential_id" name="credential_id" onChange={handleChange} value={formData.credential_id} maxLength={150} />
                                </div>
                                <div className="form-group mt-4">
                                    <label htmlFor="credential_url">Credential URL</label>
                                    <input type="url" className="form-control" placeholder="Ex: Design and Applied Arts" id="credential_url" name="credential_url" onChange={handleChange} value={formData.credential_url} maxLength={300} />
                                </div>




                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowCertificationModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditCertification;
