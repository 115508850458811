import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../store/slices/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { CircularProgress, Grid } from '@mui/material';
import ShowContactModal from 'layouts/professionals/ShowContactModal';
import CustomLoader from 'components/CustomLoader'
const ApplicantDetails = () => {
    const { slug } = useParams(); // Get the role ID from the URL params
    const { projectSlug } = useParams(); // Get the role ID from the URL params
    const [userDetails, setUserDetails] = useState([]);
    const [application, setApplication] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const [showAlert, setShowAlert] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const fetchUsertDetails = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}api/applicants/${slug}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                setUserDetails(result);
                fetchApplicationDetails(result?.id)
                console.log(result)
                setLoading(false);

            } else {
                setLoading(false);
                setError('Failed to fetch applicants details.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching role details:', error);
            setError('Failed to fetch applicants details. Please try again later.');
        }
    };

    const fetchApplicationDetails = async (userId) => {
        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}api/applications/details/${projectSlug}/${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            console.log("setApplication", result)
            if (response.ok) {
                setApplication(result);
                setLoading(false);

            } else {
                setLoading(false);
                setError('Failed to fetch applicants details.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching role applicants:', error);
            setError('Failed to fetch applicants details. Please try again later.');
        }
    };


    const updateStatus = async (status) => {
        // Display a confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to ${status === '1' ? 'approve' : 'reject'} this application?`);
        if (confirmed) {
            setLoading(true);
            try {
                const response = await axios.post(`${apiUrl}api/applications/update-application-status/${application?.id}`, { "status": status }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const result = response.data;
                    setUserDetails(result);
                    fetchUsertDetails()
                    setSuccess(true)
                    setLoading(false);
                    if (result?.is_approved == 1) {
                        toast.success("Application approved successfully !!", {
                            className: "toast-message",
                        });
                    }
                    else {
                        toast.success("Application rejected successfully !!", {
                            className: "toast-message",
                        });
                    }
                } else {
                    setLoading(false);
                    setError('Failed to fetch applicants details.');
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching application details:', error);
                setError('Failed to fetch applicants details. Please try again later.');
            }
        }
    };



    const downloadPDF = () => {
        // Retrieve the URL of the PDF file from formData.resume_attachment
        const pdfUrl = `${apiUrl}${node === 'production' ? 'public/' : ''}storage/` + userDetails?.details?.resume_attachment;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'resume.pdf'; // Set the filename for download

        // Append the anchor element to the body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };

    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    function convertDateFormat(dateString) {
        const dateParts = dateString.split('-');
        const year = dateParts[0];
        const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
        return `${month} ${year}`;
    }

    const handleContact = (email) => {
        if (email) {
            setShowModal(true)
        }
    }


    useEffect(() => {
        fetchUsertDetails();
    }, [token]);

    return (
        <BasicLayout>
            {loading && (
                <>
                    <ToastContainer
                    />
                    <div className="spinner-overlay">
                        <CustomLoader />
                    </div>
                </>

            )}
            <ShowContactModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                email={userDetails?.email}
                name={userDetails?.name}

            />
            <Grid container justifyContent="center" alignItems="center" className={showModal ? 'blurred-background' : ''}>
                <Grid item sm={9} md={12} lg={8}>
                    <div className="container-fluid mt-5 py-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-end mb-3 p-3">
                                            {application?.is_approved == 0 && (
                                                <>
                                                    <button className='btn btn-success float-end mx-3' onClick={() => updateStatus("1")}>Approve</button>

                                                    <button className='btn btn-danger float-end' onClick={() => updateStatus("2")}>Reject</button>
                                                </>
                                            )}
                                            {token && user?.user?.role_id == 3 && (
                                                <button className="btn btn-primary d-flex mx-3" onClick={() => handleContact(userDetails?.email)}
                                                >
                                                    Contact <i className="material-icons px-1">mail</i>
                                                </button>
                                            )}
                                            <button className='btn btn-dark float-end' onClick={() => navigate(-1)}>Back</button>
                                        </div>

                                        <section >
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12 mb-4 mb-sm-5">
                                                        <div className="card border-0">

                                                            <div className="card-body p-4">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-6 mb-4 mb-lg-0">
                                                                        {userDetails?.details?.profile_picture ?
                                                                            <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${userDetails?.details?.profile_picture}`} alt="Profile picture" className="img-fluid" style={{ width: '50%', height: '50%', objectFit: 'cover' }} />

                                                                            :
                                                                            <>
                                                                                <div
                                                                                    className="profile-circle position-absolute top-50 start-50 translate-middle"
                                                                                    style={{
                                                                                        width: '100px',  // Set width
                                                                                        height: '100px', // Set height (same as width for a circle)
                                                                                        borderRadius: '50%',  // This makes the div a circle
                                                                                        backgroundColor: '#f0f0f0',  // Background color of the circle
                                                                                        display: 'flex',  // Flexbox to center the content
                                                                                        alignItems: 'center',  // Vertically center the text
                                                                                        justifyContent: 'center',  // Horizontally center the text
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        className="text-uppercase"
                                                                                        style={{
                                                                                            fontSize: '30px',
                                                                                            lineHeight: '1',
                                                                                            fontWeight: 'bold'
                                                                                        }}
                                                                                    >
                                                                                        {userDetails?.name?.charAt(0)}
                                                                                    </span>
                                                                                </div>

                                                                            </>

                                                                        }

                                                                    </div>
                                                                    <div className="col-lg-6 px-xl-4">
                                                                        <div className="bg-secondary d-inline-block py-3 px-4 mb-3 rounded">
                                                                            <h3 className="h2 text-white mb-0">{userDetails?.name}</h3>
                                                                            <span className="text-white">{userDetails?.details?.designation}</span>
                                                                        </div>
                                                                        <ul className="list-unstyled mb-4">
                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Email:</strong></span> {userDetails?.email}</li>
                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Phone:</strong></span>{userDetails?.phone}</li>
                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Availability:</strong></span> {userDetails?.details?.availability}</li>
                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Experience:</strong></span> {userDetails?.details?.years_of_experience} Years</li>
                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>CV:</strong></span> <span className='text-primary' role="button" onClick={downloadPDF}>
                                                                                View
                                                                            </span></li>

                                                                        </ul>
                                                                    </div>
                                                                    {userDetails?.details?.about ?
                                                                        <div className="col-lg-12 mt-5 px-4">
                                                                            <div>
                                                                                <h2 className="section-title text-primary mb-3 mb-sm-4">About {userDetails?.name && userDetails.name.split(' ')[0]}</h2>
                                                                                <p>{userDetails?.details?.about}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='mt-5'></div>
                                                                    }

                                                                    <div className="col-lg-12 mt-2 px-4">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-4 mb-sm-5">
                                                                                <div className="mb-4 mb-sm-5">
                                                                                    <h2 className="section-title text-primary mb-3 mb-sm-4">Sectors</h2>


                                                                                    {userDetails?.sectors}
                                                                                    {userDetails?.details?.sectors && userDetails.details.sectors.map((sector, index) => (
                                                                                        <div className="badge bg-secondary mx-2 my-2 mb-3 p-3" key={index}>{sector}</div>
                                                                                    ))}

                                                                                </div>
                                                                                {userDetails?.details?.experience && (
                                                                                    <div mt-3 px-4>
                                                                                        <h2 className="section-title text-primary mb-3 mb-sm-4">Experience</h2>
                                                                                        <div className="accordion" id="accordionExample">
                                                                                            {userDetails?.details?.experience.map((exp, index) => (
                                                                                                <div className="accordion-item" key={index}>
                                                                                                    <h2 className="accordion-header" id={`heading${index}`}>
                                                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                                                                            {exp.title} <span className='px-1'> - {exp?.company_name}</span> <span className='px-1'> ({convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)})</span>
                                                                                                        </button>

                                                                                                    </h2>
                                                                                                    <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                                                                        <div className="accordion-body d-flex justify-content-between align-items-center">
                                                                                                            <div>
                                                                                                                <h5 className='text-bold'>{exp.title}</h5>
                                                                                                                <small className='text-muted fs-8'>{convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)}  {exp?.employment_type && (` (${exp?.employment_type})`)}</small>
                                                                                                                <h6 className="pt-3"><strong>Company Name: </strong><span>{exp?.company_name}</span></h6>
                                                                                                                <h6 className="pt-1"><strong>Location: </strong><span>{exp?.location}</span></h6>
                                                                                                                {exp?.description && (
                                                                                                                    <p className='text-muted pt-3'>
                                                                                                                        <small>{showFullDescription ?
                                                                                                                            <span dangerouslySetInnerHTML={{ __html: exp?.description }} /> :
                                                                                                                            <span dangerouslySetInnerHTML={{ __html: exp?.description.substring(0, 200) + "..." }} />
                                                                                                                        }
                                                                                                                            {exp?.description.length > 200 && (
                                                                                                                                <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                                                                                    {showFullDescription ? " View less" : " View more"}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </small>
                                                                                                                    </p>
                                                                                                                )}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            ))}
                                                                                        </div>

                                                                                    </div>
                                                                                )}
                                                                                <div mt-3>
                                                                                    <h2 className="section-title text-primary mb-3 mb-sm-4 mt-4">Other Info  </h2>
                                                                                    <ul className="list-unstyled mb-4">
                                                                                        {userDetails?.details?.current_location && (
                                                                                            <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Current Location:</strong></span> {userDetails?.details?.current_location}</li>
                                                                                        )}
                                                                                        {userDetails?.details?.preferred_location?.length > 0 && (
                                                                                            <li className="mb-3 display-7">
                                                                                                <span className="text-secondary me-2 font-weight-bold"><strong>Preferred Location:</strong></span>
                                                                                                {userDetails.details.preferred_location.join(' || ')}
                                                                                            </li>
                                                                                        )}


                                                                                        {userDetails?.details?.current_employer && (
                                                                                            <li className="mb-3 display-7">
                                                                                                <span className="text-secondary me-2 font-weight-bold"><strong>Current Employer:</strong></span>
                                                                                                {userDetails.details.current_employer}
                                                                                            </li>
                                                                                        )}
                                                                                        {userDetails?.details?.current_salary && (
                                                                                            <li className="mb-3 display-7">
                                                                                                <span className="text-secondary me-2 font-weight-bold"><strong>Current Salary:</strong></span>
                                                                                                {userDetails.details.current_salary} {userDetails.details.currency}
                                                                                            </li>
                                                                                        )}
                                                                                        {userDetails?.details?.expected_salary && (
                                                                                            <li className="mb-3 display-7">
                                                                                                <span className="text-secondary me-2 font-weight-bold"><strong>Expected Salary:</strong></span>
                                                                                                {userDetails.details.expected_salary} {userDetails.details.currency}
                                                                                            </li>
                                                                                        )}

                                                                                    </ul>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </BasicLayout>
    );
}

export default ApplicantDetails;
