import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Country, State } from 'country-state-city';
import countryData from '../../../../assets/data/country-state.json';
import { Autocomplete, TextField } from '@mui/material';


const options = [
    { label: "Agriculture", value: "Agriculture" },
    { label: "Finance", value: "Finance" },
    { label: "Education", value: "Education" },
    { label: "Energy & Extractives", value: "Energy & Extractives" },
    { label: "Water/Sanit/Waste", value: "Water/Sanit/Waste" },
    { label: "Transportation", value: "Transportation" },
    { label: "Info & Communication", value: "Info & Communication" },
    { label: "Industry & Trade/Service", value: "Industry & Trade/Service" },
    { label: "Health", value: "Health" }
];

const EditProfileModal = ({ show, handleClose, data, token, handleProfileUpdate, handleDataFetch }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        designation: '',
        current_location: '',
        years_of_experience: '',
        infracon_id: '',
        citizenship: '',
        availability: ''
    });
    const [selectedSector, setSelectedSector] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedCurrentLocation, setSelectedCurrentLocation] = useState('');

    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [sectorError, setSectorError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const [stateCountryList, setStateCountryList] = useState([]);

    const countries = Country.getAllCountries();
    const states = State.getAllStates();

    const handleCountryChange = (e) => {
        console.log()
        setSelectedCountry(e.target.value);
        setSelectedState(''); // Reset state when country changes
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleKeyPress = (e) => {
        // Check if the pressed key is a digit (0-9)
        if (/\d/.test(e.key)) {
            e.preventDefault(); // Prevent the default behavior (input of digit)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedSector.length === 0) {
            setSectorError(true);
            return; // Exit handleSubmit function if sector field is empty
        }
        if (selectedLocations.length === 0) {
            setLocationError(true);
            return; // Exit handleSubmit function if sector field is empty
        }
        console.log('selectedLocations', selectedLocations)
        setSectorError(false);
        setLocationError(false);
        const currentCountry = countries.find(country => country.isoCode === selectedCountry);
        const currentState = states.find(state => state.isoCode === selectedState);
        console.log("currentCountry", currentCountry)

        const updatedData = {
            ...formData,
            sectors: selectedSector.map(sector => sector.value),
            preferred_location: selectedLocations,
            current_location: selectedCurrentLocation,

            // current_location: [
            //     {
            //         isoCode: selectedCountry,
            //         name: countries.find(country => country.isoCode === selectedCountry)?.name || ''
            //     },
            //     {
            //         isoCode: selectedState,
            //         name: states.find(state => state.isoCode === selectedState && state.countryCode === selectedCountry)?.name || ''
            //     }
            // ]
        };

        try {
            const id = data?.user?.id;
            if (id) {
                console.log("updatedData", updatedData)
                const response = await axios.put(`${apiUrl}api/professional/basic-info/${id}`, updatedData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                console.log(response.data); // Handle success response
                toast.success("Basic User Info saved successfully !!", {
                    className: "toast-message",
                });
                handleClose();
                handleDataFetch();
            }
        } catch (error) {
            console.error('Error updating user info:', error); // Log error
            // toast.error("Failed to save user info. Please try again later.", {
            //     className: "toast-message",
            // });
        }

    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }

    useEffect(() => {
        fetchCountryStateList();
        setFormData({
            fullName: data?.user?.name || '',
            designation: data?.details?.designation || '',
            years_of_experience: data?.details?.years_of_experience || '',
            infracon_id: data?.details?.infracon_id || '',
            citizenship: data?.details?.citizenship || '',
            availability: data?.details?.availability || '',

        });
        if (data?.details?.sectors) {
            const preSelectedSectors = options.filter(option =>
                data.details.sectors.includes(option.value)
            );
            setSelectedSector(preSelectedSectors);
        }
        if (data?.details?.preferred_location) {
            setSelectedLocations(data?.details?.preferred_location);
        }
        if (data?.details?.current_location) {
            setSelectedCurrentLocation(data?.details?.current_location)
        }
    }, [data]);

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable profile-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title me-auto">Edit Intro</h5>
                        <button type="button" className="close ms-auto" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span class="material-symbols-outlined"> close </span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="fullName">Full Name <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} onKeyDown={handleKeyPress} required maxLength={50}/>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="designation">Current Designation</label>
                                <input type="text" className="form-control" id="designation" name="designation" value={formData.designation} onChange={handleChange} maxLength={50}/>
                            </div>
                            {formData.citizenship && (
                                <div className="form-group mt-4">
                                    <label htmlFor="infracon_id">Infracon Id </label>
                                    <input type="text" className="form-control" id="infracon_id" name="infracon_id" value={formData.infracon_id} onChange={handleChange}  maxLength={100}/>
                                </div>
                            )}
                            <div className="form-group mt-4">
                            <label htmlFor="availability">Availability <span className='text-danger'>*</span></label>
                            <select className="form-control mt-2" id="availability" name="availability" value={formData?.availability} onChange={handleChange}>
                                <option value="">Select Availability</option>
                                <option value="Immediately">Immediately</option>
                                <option value="Proposal">Proposal</option>
                                <option value="Any Opportunity">Any Opportunity</option>
                                <option value="Not Available">Not Available</option>

                            </select>
                        </div>
                            <div className="form-group mt-4">
                                <label htmlFor="total_exprience">Total Experience (in years) <span className='text-danger'>*</span></label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    id="years_of_experience"
                                    name="years_of_experience"
                                    value={formData.years_of_experience}
                                    required
                                    onChange={handleChange}
                                    min="0"
                                    max="70"
                                    step="0.1" // This allows decimal increments
                                />

                            </div>
                            <div className='form-group mt-4 mb-4'>
                                <label htmlFor="sector">Sector <span className='text-danger'>*</span></label>
                                <MultiSelect
                                    options={options}
                                    value={selectedSector}
                                    name="sectors"
                                    onChange={setSelectedSector}
                                    labelledBy="Select"
                                    className={`mt-2 ${sectorError ? 'is-invalid' : ''}`}
                                    required
                                />
                                {sectorError && <div className="invalid-feedback">Please select at least one sector.</div>}

                            </div>

                            <div className='row mt-4'>
                                <label htmlFor="location">Current Location <span className='text-danger'>*</span></label>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        {/* <select value={selectedCountry} onChange={handleCountryChange} className="form-control" id="country" name="country" required>
                                            <option value="">Select Country</option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country.isoCode}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select> */}
                                        <Autocomplete
                                            options={stateCountryList}
                                            freeSolo
                                            className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                                            value={selectedCurrentLocation}
                                            required
                                            onChange={(event, newValue) => setSelectedCurrentLocation(newValue)}
                                            renderInput={(params) => <TextField {...params} label="" />}
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className="form-group">
                                        <select value={selectedState} onChange={handleStateChange} className="form-control" required>
                                            <option value="">Select State</option>
                                            {states
                                                .filter((state) => state.countryCode === selectedCountry)
                                                .map((state, index) => (
                                                    <option key={index} value={state.isoCode} name={state.isoCode}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                        </select>


                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="form-group mt-4">
                                <label htmlFor="location">Current Location <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" id="location" name="current_location" placeholder='City, State, Country' value={formData.current_location} onChange={handleChange} required />
                            </div> */}
                            <div className='form-group mt-4 mb-4'>
                                <label htmlFor="sector">Preferred Location <span className='text-danger mb-3'>*</span></label>
                                {/* <MultiSelect
                                    options={allLocations}
                                    value={selectedLocations}
                                    onChange={setSelectedLocations}
                                    labelledBy="Select"
                                    className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                                    required
                                /> */}
                                <Autocomplete
                                    multiple // Allows multiple selections
                                    options={stateCountryList}
                                    freeSolo
                                    className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                                    value={selectedLocations}
                                    required
                                    onChange={(event, newValue) => setSelectedLocations(newValue)}
                                    renderInput={(params) => <TextField {...params} label="" />}
                                />
                                {locationError && <div className="invalid-feedback">Please select at least one location.</div>}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={handleClose}>Close</button>
                                <button type="submit" className="btn btn-success">Update</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default EditProfileModal;
