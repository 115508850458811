import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import CustomLoader from 'components/CustomLoader'
const Index = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const { token, user } = useSelector(selectAuth);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedAvailability, setSelectedAvailability] = useState('');
    const [selectedExperience, setSelectedExperience] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [infraChecked, setInfraChecked] = useState(false);
    const [isReadMore, setIsReadMore] = useState(false);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}api/seekers`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         const result = await response.json();
    //         setData(result);
    //         setFilteredData(result);
    //         setUserData(user)
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorName = event.target.value;
        setSelectedSector(sectorName);
    };

    const handleAvailabilityChange = (event) => {
        const value = event.target.value;
        setSelectedAvailability(value);
    };

    const handleExperienceChange = (event) => {
        const value = event.target.value;
        setSelectedExperience(value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };

    const fetchFilteredData = async () => {
        try {
            setLoading(true);
            // Build query parameters for filtering
            const queryParams = new URLSearchParams();
            if (selectedLocations.length > 0) {
                queryParams.append('locations', selectedLocations.join(','));
            }

            if (selectedSector) {
                queryParams.append('sector', selectedSector);
            }

            if (selectedAvailability) {
                queryParams.append('availability', selectedAvailability);
            }

            if (selectedExperience) {
                queryParams.append('experience', selectedExperience);
            }

            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            if (infraChecked) {
                queryParams.append('infraChecked', infraChecked); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/seekers?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result);

                setData(result?.data?.data || []);
                setUserData(user)
                setCurrentPage(result.data.current_page); // Set current page
                setTotalPages(result.data.last_page); // Set total pages
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };


    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }
    const fetchSectorsData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        // fetchData();
        fetchFilteredData();
        fetchSectorsData();
        fetchCountryStateList();
    }, [token, selectedSector, selectedAvailability, selectedExperience, currentPage, infraChecked]);
    return (
        <BasicLayout>
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <div className='inner-banner'>
                <div className='container d-flex justify-content-between align-items-center'>
                    <h2>Professionals</h2>
                </div>
            </div>
            <div className="container professional-listing-wrapper mt-2">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='project-filters'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='form-group location-fild col-md-12'>
                                            <div className="form-group position-relative">
                                                <label>Search by Location</label>
                                                <Autocomplete
                                                    multiple // Allows multiple selections
                                                    options={stateCountryList}
                                                    freeSolo
                                                    value={selectedLocations}
                                                    onChange={(event, newValue) => {
                                                        setSelectedLocations(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />
                                                <button className="btn btn-primary loc-serch mt-1" type="button" onClick={fetchFilteredData}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Select Sector</label>
                                            <select
                                                className="form-control mt-2"
                                                id="type"
                                                name="type"
                                                value={selectedSector}
                                                onChange={(event) => {
                                                    handleSectorChange(event);
                                                }}
                                            >
                                                <option value="">Select Sector</option>
                                                {sectors.map((sector) => (
                                                    <option key={sector.name} value={sector.name}>{sector.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Select Experience</label>
                                            <select
                                                className="form-control mt-2"
                                                id="Experience"
                                                name="Experience"
                                                value={selectedExperience}
                                                onChange={(event) => {
                                                    handleExperienceChange(event);
                                                }}
                                            >
                                                <option value="">Select Experience</option>
                                                <option key='0-2yr' value='0-2'>0-2 yr</option>
                                                <option key='2-5yr' value='2-5'>2-5 yr</option>
                                                <option key='5-10yr' value='5-10'>5-10 yr</option>
                                                <option key='10yr+' value='10'>10 yr+</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Select Availability</label>
                                            <select
                                                className="form-control mt-2"
                                                id="availability"
                                                name="availability"
                                                value={selectedAvailability}
                                                onChange={(event) => {
                                                    handleAvailabilityChange(event);
                                                }}
                                            >
                                                <option value="">Select Availability</option>
                                                <option value="Immediately">Immediately</option>
                                                <option value="Proposal">Proposal</option>
                                                <option value="Any Opportunity">Any Opportunity</option>
                                                <option value="Not Available">Not Available</option>
                                            </select>
                                        </div>
                                        <div className='form-group location-fild col-md-12'>
                                            <div className="form-group position-relative">
                                                <label>Search by Name/Designation</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name/Designation"
                                                    aria-describedby="button-addon2"
                                                    value={searchTerm}
                                                    onChange={handleSearchTermChange}
                                                />
                                                <button className="btn btn-primary" type="button" onClick={fetchFilteredData}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-12 mt-1'>

                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value={infraChecked}
                                                    onChange={() => setInfraChecked(!infraChecked)} />
                                                <label class="form-check-label" for="flexSwitchCheckChecked">Infracon Registered</label>
                                            </div>
                                        </div>


                                        <div className='form-group col-md-12 mt-2 float-end'>
                                            <button
                                                className="btn btn-warning mt-2"
                                                onClick={() => {
                                                    setSelectedLocations([]);
                                                    setSelectedSector('');
                                                    setSelectedAvailability('');
                                                    setSearchTerm('');
                                                    setSelectedExperience('');
                                                    setInfraChecked(false)
                                                    fetchFilteredData(); // Trigger fetch on clearing filters
                                                }}
                                            >
                                                Clear Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-9'>
                        {data?.length > 0
                            ?
                            <>
                                {data?.map((user, index) => (
                                    <>
                                        <div className="col-md-12 py-3" key={index}>
                                            <div className="card project-list shadow">
                                                <div className="card-body">
                                                    <div className="row align-items-center company-title">
                                                        <div className="col-md-2">
                                                            <div className="profile-wrapper d-inline-flex align-items-center justify-content-center" style={{ backgroundColor: user?.details?.profile_picture ? '#fff' : '#fff', color: user?.details?.profile_picture ? '#fff' : '#000' }}>

                                                                {user?.details?.profile_picture ? (
                                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.details?.profile_picture}`} alt="Profile picture" />
                                                                ) : (
                                                                    <p className="letter-cap"><strong>{user?.name?.charAt(0)}</strong></p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <Link to={`/profile/${user?.slug}`}>
                                                                <h2>{user?.name}</h2>
                                                            </Link>
                                                            <p>{user?.details?.designation}</p>
                                                            {user?.details?.resume_attachment && (
                                                                <>
                                                                    {token && userData && userData?.user?.role_id == 3 || userData?.user?.role_id == 1 ?
                                                                        <Link to={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.details?.resume_attachment}`} target='_blank'>
                                                                            <p className='applicants_profile text-end text-success'>
                                                                                <span class="material-symbols-outlined">draft</span> CV available
                                                                            </p>
                                                                        </Link>
                                                                        :
                                                                        <p className='applicants_profile text-end text-success'>
                                                                            <span class="material-symbols-outlined">draft</span> CV available
                                                                        </p>

                                                                    }
                                                                </>

                                                            )}
                                                        </div>

                                                    </div>
                                                    <hr className='mb-1'></hr>
                                                    <div className='meta-info'>
                                                        {user?.details?.experience && (
                                                            <>
                                                                <div className='work-exprience'>

                                                                    <label>Work Experience</label>
                                                                    <ul className='list-unstyled'>
                                                                        {isReadMore
                                                                            ? user?.details?.experience.map((exp, index) => (
                                                                                <li key={index}>
                                                                                    <span className="material-icons">chevron_right</span>{exp.title} - {exp?.company_name}
                                                                                </li>
                                                                            ))
                                                                            : user?.details?.experience.slice(0, 3).map((exp, index) => (
                                                                                <li key={index}>
                                                                                    <span className="material-icons">chevron_right</span>{exp.title} - {exp?.company_name}
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    {/* "Read more" / "Read less" button */}
                                                                    {user?.details?.experience.length > 3 && (
                                                                        <p className="text-primary" onClick={toggleReadMore} style={{ cursor: 'pointer' }}>
                                                                            {isReadMore ? 'Read less' : 'Read more'}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <hr></hr>
                                                            </>


                                                        )}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div>
                                                                    <label>Experience</label>
                                                                    <h6>{user?.details?.years_of_experience ?? 0} years</h6>
                                                                </div>
                                                            </div>
                                                            {user?.details?.citizenship && (
                                                                <div className="col">
                                                                    <div>
                                                                        <label>Citizenship</label>
                                                                        <h6>{user?.details?.citizenship}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {user?.details?.current_location && (
                                                                <div className="col">
                                                                    <div>
                                                                        <label>Currently in</label>
                                                                        <h6>{user?.details?.current_location}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>

                                ))}
                            </>
                            :
                            <>
                                <div className="col-md-12 mt-5">
                                    <div className="card-shadow">
                                        <h2 className='text-center'>No Records Found !!</h2>
                                    </div>
                                </div>
                            </>
                        }
                    </div>


                </div>
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
                <MDPagination size="medium">
                    {currentPage > 1 && (
                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, i) => (
                        <MDPagination item
                            key={i + 1}
                            active={currentPage === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </MDPagination>
                    ))}
                    {currentPage < totalPages && (
                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            )}
        </BasicLayout>

    )
}

export default Index