import React, { useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import CustomLoader from 'components/CustomLoader'

const ShowContactModal = ({ show, handleClose, user, token, email, name }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [formData, setFormData] = useState({
        message: '',
    });
    const [loading, setLoading] = useState(false);


    const handleInputChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true

        try {
            const response = await axios.post(`${apiUrl}api/professional/contact/${email}/${name}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            setLoading(false); // Set loading to false
            toast.success("Message successfully sent !!", {
                className: "toast-message",
            });
            handleClose();
            setFormData({ message: '' });

        } catch (error) {
            console.error('Error in sending contact message:', error);
        }
    };


    return (
        <>
            <ToastContainer />
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">

                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Contact {name}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true" className='p-2'>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body w-100 h-100">
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <>
                                    <div className='card border-0'>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="total_exprience">Message <span className='text-danger'>*</span></label>
                                                <textarea className="form-control mt-2"
                                                    id="message"
                                                    name="message"
                                                    value={formData.message}
                                                    rows="7"
                                                    required
                                                    onChange={handleInputChange}>

                                                </textarea>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>

                                                <button type="submit" className="btn btn-primary" disabled={loading}>Send</button>
                                            </div>
                                        </div>
                                    </div>

                                </>


                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
};

export default ShowContactModal;
